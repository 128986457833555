import React, { useState } from "react";
import jsFileDownload from "js-file-download";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { toast } from "react-toastify";
import { Table } from "flowbite-react";
import AdminLayout from "../../Layout";
import { useParams } from "react-router-dom";
import {
  useGetAssignementQuery,
  useGetAssignmentLeaderBoardQuery,
  useSendEmailMutation,
} from "../../../../graphql/generated.tsx";
import PDFDocument from "../../Components/AssignmentPdfDocument.jsx";
import { FaMedal } from "react-icons/fa";
import { SkeletonRow } from "../../../Student/Components/LeaderBoardSkeleton.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";

const AssignmentReport = () => {
  const { assignment_id,campusName } = useParams();
  const [showEmailDropdown, setShowEmailDropdown] = useState(false);
  const [emails, setEmails] = useState("");
  const [emailError, setEmailError] = useState("");

  const {
    data: LeaderBoardData,
    loading,
    error,
  } = useGetAssignmentLeaderBoardQuery({
    variables: { assignmentId: assignment_id, campus_name: campusName },
  });

  const { data: Assignment } = useGetAssignementQuery({
    variables: { id: assignment_id },
  });

  const [sendEmail] = useSendEmailMutation();

  const validateEmails = (emailString) => {
    const emailArray = emailString.split(",").map((email) => email.trim());
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailArray.every((email) => emailPattern.test(email));
  };

  const handleSendEmail = async () => {

    if (!validateEmails(emails)) {
      setEmailError("Please enter valid email addresses separated by commas.");
      return;
    }
    setEmailError("");

    const emailList = emails.split(",").map((email) => email.trim());

    const csvHeaders = [
      "Name",
      "Roll No",
      "Points",
      "Questions Attended",
      "Questions Completed",
      "Batch Enrolled",
      "Progress (%)",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" + // Join headers with commas and add a newline
      LeaderBoardData.getAssignmentLeaderBoard
        .map((assign) => {

          const questionAttended = assign.questions.filter(
            (question) => question.score > 0
          ).length;
          const questionCompleted = assign.questions.filter(
            (question) => question.score === question.AllotedTotalMarks
          ).length;
         
          const progressPercentage = Math.round(
            (questionCompleted / assign.totalNumberOfQuestion) * 100
          );

          return [
            `"${assign.student.name}"`, // Enclose name in quotes to handle commas in names
            assign.student.rollNo,
            assign.totalScore,
            questionAttended,
            questionCompleted,
            `${assign.clusterNames}`,
            `${progressPercentage}%`,
          ].join(","); // Join each row's data with commas
        })
        .join("\n");

    const pdfDoc = (
      <PDFDocument
        students={LeaderBoardData?.getAssignmentLeaderBoard}
        assignmentName={Assignment?.getAssignement.name}
      />
    );

    const pdfBlob = await pdf(pdfDoc).toBlob();
    const pdfBase64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result.split(",")[1];
        resolve(base64data);
      };
    });

    try {
      await sendEmail({
        variables: {
          emailInput: {
            emailList,
            csvContent,
            assignmentName: Assignment?.getAssignement.name,
          },
        },
      });
      toast.success("Email sent successfully!");
      setShowEmailDropdown(false);
      setEmails("");
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  const downloadCSV = () => {
    if (!LeaderBoardData?.getAssignmentLeaderBoard) return;

    const csvHeaders = [
      "Position",
      "Name",
      "Roll No",
      "Points",
      "Questions Attended",
      "Questions Completed",
      "Batch Enrolled",
      "Progress (%)",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" + // Join headers with commas and add a newline
      LeaderBoardData.getAssignmentLeaderBoard
        .map((assign) => {
          console.log({ assign });
          const questionAttended = assign.questions.filter(
            (question) => question.score > 0
          ).length;
          const questionCompleted = assign.questions.filter(
            (question) => question.score === question.AllotedTotalMarks
          ).length;
          const progressPercentage = Math.round(
            (questionCompleted / assign.totalNumberOfQuestion) * 100
          );

          return [
            `${assign?.position || ""}`, // Use empty string if undefined
            `"${assign?.student?.name || "-"}"`, // Ensure student and name are defined
            `"${assign?.student?.rollNo || "-"}"`, // Ensure rollNo is defined
            `"${assign?.totalScore || "0"}"`,
            `"${questionAttended}"`,
            `"${questionCompleted}"`,
            `${assign.clusterNames}`,
            `${progressPercentage}%`,
          ].join(","); // Join each row's data with commas
        })
        .join("\n");

    const assignmentName = Assignment?.getAssignement?.name || "leaderboard"; // Default name if undefined
    jsFileDownload(csvContent, `${assignmentName}.csv`);
  };

  const getPositionIcon = (position) => {
    if (position === 1) return <FaMedal className="text-yellow-500 text-center" />;
    if (position === 2) return <FaMedal className="text-gray-400 text-center" />;
    if (position === 3) return <FaMedal className="text-yellow-800 text-center" />;
    return position;
  };

  const renderProgressSegments = (assign) => {
    const totalQuestions = assign.totalNumberOfQuestion;
    const completedQuestions = assign.questions.filter(
      (question) => question.score === question.AllotedTotalMarks
    ).length;

    const completionRate = completedQuestions / totalQuestions;
    const segmentCount = 3;
    const filledSegments = Math.round(completionRate * segmentCount);

    return (
      <div className="flex space-x-1">
        {[...Array(segmentCount)].map((_, index) => (
          <div
            key={index}
            className={`w-6 h-1.5 rounded-full ${
              index < filledSegments ? "bg-primary" : "bg-gray-200"
            }`}
          />
        ))}
      </div>
    );
  };

  return (
    <AdminLayout>
      <div className="overflow-x-auto m-10 bg-white pt-5 shadow-lg rounded-lg p-8">
        <div className="flex justify-between w-full mb-10 relative">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              {Assignment?.getAssignement.name}
            </div>
          </div>
          <div className="flex items-center justify-between mb-5">
            <div>
              <button
                onClick={downloadCSV}
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
              >
                Download CSV
              </button>
              <PDFDownloadLink
                document={
                  <PDFDocument
                    students={LeaderBoardData?.getAssignmentLeaderBoard}
                    assignmentName={Assignment?.getAssignement.name}
                  />
                }
                fileName={`${Assignment?.getAssignement.name}-leaderboard.pdf`}
              >
                {({ loading }) => (
                  <button className="mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200">
                    {loading ? "Loading document..." : "Download PDF"}
                  </button>
                )}
              </PDFDownloadLink>
              <button
                onClick={() => setShowEmailDropdown(!showEmailDropdown)}
                className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition duration-200"
              >
                Share Now
              </button>
            </div>
          </div>
          {showEmailDropdown && (
            <div className="absolute right-0 top-[50px] mt-2 w-96 bg-white shadow-lg p-4 rounded-lg z-10">
              <textarea
                className="w-full p-2 border rounded-lg"
                placeholder="Enter email addresses separated by commas"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
              {emailError && (
                <p className="text-red-500 text-sm mt-2">{emailError}</p>
              )}
              <button
                onClick={handleSendEmail}
                className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full transition duration-200"
              >
                Send Now
              </button>
            </div>
          )}
        </div>
        <Table className="min-w-full text-sm text-left text-gray-500 shadow-none mb-8">
          <Table.Head className="bg-gray-100">
            {[
              "Position",
              "Name",
              "Roll No",
              "Points",
              "Questions Attended",
              "Questions Completed",
              "Batch Enrolled",
              "Progress",
            ].map((heading) => (
              <Table.HeadCell className="py-3 px-6 text-center" key={heading}>
                {heading}
              </Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body className="bg-white divide-y divide-gray-200">
            {loading ? (
              <>
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
              </>
            ) : (
              LeaderBoardData?.getAssignmentLeaderBoard.map((assign, index) => {
                const questionAttended = assign.questions.filter(
                  (question) => question.score > 0
                ).length;
                const questionCompleted = assign.questions.filter(
                  (question) => question.score === question.AllotedTotalMarks
                ).length;
                return (
                  <Table.Row key={assign.student?.id || index}>
                    <Table.Cell className="text-center flex">
                      {getPositionIcon(assign.position)}
                    </Table.Cell>
                    <Table.Cell className="py-3 font-medium text-gray-900 text-center min-w-[200px]">
                      {assign.student?.email || "Unknown"}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.student?.rollNo || "N/A"}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.totalScore || 0}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {questionAttended}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {questionCompleted}/{assign.totalNumberOfQuestion}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.clusterNames}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {renderProgressSegments(assign)}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
        {error && (
          <div className="mt-5 text-red-500">
            Error loading leaderboard. Please try again later.
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AssignmentReport;
