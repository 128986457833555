import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  useCreateStaffMutation,
  useGetStaffsByCampusQuery,
  useSendInviteEmailToStaffMutation,
  useDeleteStaffMutation,
  useGetCampusQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import {
  IoMdAdd,
  IoMdArrowRoundBack,
  IoMdSend,
  IoMdTrash,
} from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";
import { Button, Table, Modal, Label, TextInput } from "flowbite-react";
import { toast } from "react-toastify";

const CampusDetails = () => {
  const { id } = useParams();
  const { loading, error, data } = useGetStaffsByCampusQuery({
    variables: { campusId: id },
  });
  const { data: campusData } = useGetCampusQuery({ variables: { id } });
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [createStaff] = useCreateStaffMutation();
  const [inviteStaff] = useSendInviteEmailToStaffMutation();
  const [deleteStaff] = useDeleteStaffMutation();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);

  useEffect(() => {
    if (data) {
      setStaff(data.getStaffsByCampus);
    }
  }, [data]);

  const handleInviteStaff = async (emails) => {
    try {
      await inviteStaff({
        variables: { emails },
      });
      console.log("Invite sent successfully");
      // Update staff state to reflect invite sent
      setStaff((prev) =>
        prev.map((s) =>
          emails.includes(s.email) ? { ...s, isInviteSend: true } : s
        )
      );
    } catch (err) {
      console.error("Error inviting staff:", err);
    }
  };

  const handleCreateStaff = async () => {
    try {
      const { data: newStaffData } = await createStaff({
        variables: { createStaffInput: { name, email, campusId: id } },
      });

      if (newStaffData.createStaff.message.includes("already exists")) {
        toast.error("Email already exists");
      } else {
        setStaff((prev) => [...prev, newStaffData.createStaff.staff]);
        setOpenModal(false);
      }
    } catch (err) {
      console.error("Error creating staff:", err);
    }
  };

  const handleSelectStaff = (email) => {
    setSelectedStaff((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]
    );
  };

  const handleBulkInvite = () => {
    handleInviteStaff(selectedStaff);
    setSelectedStaff([]);
  };

  const handleDeleteStaff = async (id) => {
    try {
      await deleteStaff({
        variables: { id },
      });
      setStaff((prev) => prev.filter((s) => s.id !== id));
      toast.success("Staff deleted successfully");
    } catch (err) {
      console.error("Error deleting staff:", err);
      toast.error("Failed to delete staff");
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              {campusData?.campus?.name} Staff Details
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleBulkInvite}
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0"
              disabled={selectedStaff.length === 0}
            >
              <IoMdSend className="mr-1 mt-1" /> <span>Send Bulk Invite</span>
            </Button>
            <Button
              onClick={() => setOpenModal(true)}
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0"
            >
              <IoMdAdd className="mr-1 mt-1" /> <span>Create Staff</span>
            </Button>
          </div>
        </div>
        <div className="mt-10">
          <Table className="min-w-full bg-white border border-gray-300 ">
            <Table.Head>
              <Table.HeadCell>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setSelectedStaff(
                      e.target.checked ? staff.map((s) => s.email) : []
                    )
                  }
                  checked={
                    selectedStaff.length === staff.length && staff.length > 0
                  }
                />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {staff?.map((staff) => (
                <Table.Row
                  key={staff.id}
                  onMouseEnter={() => setHoveredRow(staff.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className="hover:bg-gray-50"
                >
                  <Table.Cell>
                    <input
                      type="checkbox"
                      checked={selectedStaff.includes(staff.email)}
                      onChange={() => handleSelectStaff(staff.email)}
                    />
                  </Table.Cell>
                  <Table.Cell>{staff.name}</Table.Cell>
                  <Table.Cell>{staff.email}</Table.Cell>
                  <Table.Cell className="flex items-center gap-1">
                    {staff.isInviteSend ? (
                      <MdCheckCircle className="text-green-500 h-6 w-6" />
                    ) : (
                      hoveredRow === staff.id && (
                        <IoMdSend
                          className="mr-1 h-5 w-5 cursor-pointer"
                          onClick={() => handleInviteStaff([staff.email])}
                        />
                      )
                    )}
                    {hoveredRow === staff.id && (
                      <IoMdTrash
                        className="mr-1 h-5 w-5 cursor-pointer text-red-500"
                        onClick={() => handleDeleteStaff(staff.id)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        {/* Modal for creating staff */}
        <Modal
          show={openModal}
          size="md"
          popup
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Create a new staff member
              </h3>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Staff Name" />
                </div>
                <TextInput
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter staff name"
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email" value="Staff Email" />
                </div>
                <TextInput
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter staff email"
                  required
                />
              </div>
              <div className="w-full">
                <Button onClick={handleCreateStaff}>Create Staff</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default CampusDetails;
