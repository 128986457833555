import React from "react";

import { Table } from "flowbite-react";

const RunModel = ({ tableRef, isChecked, testResults, loading }) => {
  if (loading) {
    return (
      <div className="mt-8 w-full">
        <h4 className="text-xl font-semibold">Loading</h4>
      </div>
    );
  }

  return (
    <div className="mt-8 w-full">
      <h4 className="text-xl font-semibold">Test Results:</h4>
      <Table
        ref={tableRef}
        className="w-full mt-2 border border-black outline-black"
      >
        <Table.Head>
          <Table.HeadCell className="border border-black px-4 py-2">
            Input
          </Table.HeadCell>
          <Table.HeadCell className="border border-black px-4 py-2">
            Output
          </Table.HeadCell>
          {!isChecked && (
            <>
              <Table.HeadCell className="border border-black px-4 py-2">
                Expected Output
              </Table.HeadCell>
              <Table.HeadCell className="border border-black px-4 py-2">
                Result
              </Table.HeadCell>
            </>
          )}
        </Table.Head>
        <Table.Body>
          {testResults?.map((result, index) => (
            <Table.Row key={index}>
              <Table.Cell className="border border-black px-4 py-2">
                {result.input}
              </Table.Cell>
              <Table.Cell className="border border-black px-4 py-2">
                {result.output}
              </Table.Cell>
              {!isChecked && (
                <>
                  <Table.Cell className="border border-black px-4 py-2">
                    {result.expextedOutput}
                  </Table.Cell>
                  <Table.Cell
                    className={`border border-black px-4 py-2 ${
                      result.expextedOutput === result.output
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {result.expextedOutput === result.output
                      ? "Passed"
                      : "Failed"}
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default RunModel;
