import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  InteractionMode,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const AcSubmissionChart = ({ acSubmissionNum, isLoading }) => {

  console.log({ acSubmissionNum });
  // Filter out the data for Easy, Medium, and Hard difficulties only
  const filteredData = acSubmissionNum?.filter(
    (item) =>
      item.difficulty === "Easy" ||
      item.difficulty === "Medium" ||
      item.difficulty === "Hard"
  );

  // Prepare data for the Donut chart based on Easy, Medium, and Hard counts
  const donutData = {
    labels: ["Easy", "Medium", "Hard"],
    datasets: [
      {
        data: filteredData?.map((item) => item.count),
        backgroundColor: ["#0E5B54", "#5B2E0E", "#0E3B5B"], // Easy, Medium, Hard colors
        hoverBackgroundColor: ["#0E5B54", "#5B2E0E", "#0E3B5B"], // Light colors for hover effect
      },
    ],
  };

  const donutOptions = {
    cutout: "80%", // Makes the donut chart look more like a ring
    plugins: {
      legend: {
        display: false, // Hide legend for donut chart
      },
    },
    interaction: {
      mode: "nearest", // Enable hover interactions
    },
  };

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <div className="bg-white py-8 rounded-lg shadow-md flex flex-col items-center px-28 w-full max-md:px-2">
      {/* Donut Chart */}
      <div
        className="relative w-full mb-5 flex justify-center items-center"
        style={acSubmissionNum ? { height: "200px" } : { height: "255px" }}
      >
        <Doughnut data={donutData} options={donutOptions} />
        {/* Center text in the donut chart */}
        <div className="absolute flex flex-col items-center justify-center text-xl font-bold">
          {filteredData?.reduce((sum, item) => sum + item.count, 0)}
          <br />
          <div className="text-sm font-light inline-block">
            Questions Solved
          </div>
        </div>
      </div>

      {/* Category labels with counts */}
      <div className="flex justify-around w-full mt-1">
        {filteredData?.map((item, index) => (
          <div key={item.difficulty} className="text-center">
            <div className="text-lg font-bold">
              {item.count} {"/"}{" "}
              {filteredData?.reduce((sum, item) => sum + item.count, 0)}
            </div>
            <div className="text-xs text-gray-500 mt-1">
              <span
                className="inline-block px-2 py-1 rounded-md text-white"
                style={{
                  backgroundColor: donutData.datasets[0].backgroundColor[index],
                  // Adjust the badge color to a lighter shade
                }}
              >
                {item.difficulty}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcSubmissionChart;
