// src/components/StaffOnboarding.js
import React, { useEffect, useState } from "react";
import { useUpdateStaffMutation } from "../../../../graphql/generated.tsx";
import { Button, Label, TextInput } from "flowbite-react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../utils/firebase.js";
import { toast } from "react-toastify";

const StaffOnboarding = () => {
  const { id } = useParams();
  const [designation, setDesignation] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [updateStaff, { loading, error }] = useUpdateStaffMutation();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const [email, setEmail] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
        console.log({ user });
      }
    });
    return () => unsubscribe();
  }, []);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(contactNo)) {
      toast.error(
        "Invalid contact number. Please enter a 10-digit contact number."
      );
      return;
    }
    updateStaff({
      variables: { updateStaffInput: { id, email, designation, contactNo } },
    });
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="relative max-w-2xl w-full bg-white shadow-md rounded-lg p-6">
        <div className="absolute top-4 right-4">
          <img src={"/favicon.ico"} alt="Company Logo" className="h-12" />
        </div>

        {step === 1 && (
          <div className="p-4 text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Welcome to Staff Onboarding
            </h2>
            <p className="text-gray-600 mb-8">
              Please follow the instructions to complete your onboarding
              process.
            </p>
            <Button
              onClick={() => setStep(2)}
              className="w-full p-0 bg-primary text-white rounded-md"
            >
              Next Step
            </Button>
          </div>
        )}

        {step === 2 && (
          <div className="p-8">
            <div className="flex items-center mb-4">
              <button
                onClick={() => setStep(1)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
              >
                <IoMdArrowRoundBack className="h-6 w-6" />
              </button>
              <h2 className="text-2xl font-bold text-gray-800">
                Complete Your Profile
              </h2>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <Label htmlFor="designation">Designation:</Label>
                <TextInput
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label>Contact Number:</Label>
                <TextInput
                  type="text"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  required
                />
              </div>
              <Button
                type="submit"
                className="w-full p-0 bg-primary text-white rounded-md"
              >
                Update Staff
              </Button>
              {loading && (
                <p className="text-blue-500 text-center mt-2">Loading...</p>
              )}
              {error && (
                <p className="text-red-500 text-center mt-2">
                  Error: {error.message}
                </p>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffOnboarding;
