import React from "react";
import { Table } from "flowbite-react";

const SampleTestCaseTable = ({ question }) => {
  return (
    <Table className="w-full mt-2 border border-black outline-black">
      <Table.Head>
        <Table.HeadCell className="border border-black px-4 py-2">
          Input
        </Table.HeadCell>
        <Table.HeadCell className="border border-black px-4 py-2">
          Output
        </Table.HeadCell>
      </Table.Head>
      <Table.Body>
        {question?.test_cases?.map(
          (test) =>
            test.visibility === "SAMPLE" && (
              <Table.Row key={test.id}>
                <Table.Cell className="border border-black px-4 py-2">
                  {test.input}
                </Table.Cell>
                <Table.Cell className="border border-black px-4 py-2">
                  {test.output}
                </Table.Cell>
              </Table.Row>
            ),
        )}
      </Table.Body>
    </Table>
  );
};

export default SampleTestCaseTable;
