import { Card } from "flowbite-react";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import AdminLayout from "../../Layout";

const ReportType = () => {
  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg text-gray-800">
              Reports
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <Link to={`/admin/reports/assignment`}>
            <Card className="h-[200px] flex items-center justify-center transform transition-transform duration-300 hover:scale-105 hover:shadow-lg bg-white">
              <h5 className="text-2xl font-bold tracking-tight">Assignments</h5>
            </Card>
          </Link>
          <Link to={`/admin/reports/course`}>
            <Card className="h-[200px] flex items-center justify-center transform transition-transform duration-300 hover:scale-105 hover:shadow-lg bg-white">
              <h5 className="text-2xl font-bold tracking-tight">Course</h5>
            </Card>
          </Link>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ReportType;
