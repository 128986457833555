import React from "react";
import SampleTestCaseTable from "../../Practice/Components/SampleTestCaseTable.jsx";

const CompilerQuestionCard = ({ question, loading }) => {
  const onCopyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (loading) {
    return (
      <div className="shadow-md bg-white p-8 rounded-md items-center">
        Loading
      </div>
    );
  }

  return (
    <div
      className="relative shadow-md bg-white p-8 rounded-md"
      onCopy={onCopyHandler}
    >
      <h3 className="text-2xl font-semibold">{question?.title}</h3>
      <div
        className="py-2"
        dangerouslySetInnerHTML={{ __html: question?.text }}
      />
      <p className="mt-4 text-gray-600 dark:text-gray-400">
        {question?.question_sub_text}
      </p>

      <h4 className="text-xl font-semibold mt-4">Sample test case:</h4>
      <div className="w-1/2">
        <SampleTestCaseTable question={question} />
      </div>
      <div className="mt-4">
        {question?.hints && question?.hints?.length !== 0 && (
          <span className="font-semibold text-gray-600 dark:text-gray-400">
            Hints:
          </span>
        )}
        <ul>
          {question?.hints ? (
            question.hints.map((hint, index) =>
              hint.length > 0 ? (
                <li key={index}>
                  Hint {index + 1}: {hint}
                </li>
              ) : null
            )
          ) : (
            <li>No hints available</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CompilerQuestionCard;
