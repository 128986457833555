import React, { useEffect, useState } from "react";
import { Badge, Button, Tabs } from "flowbite-react";
import SampleTestCaseTable from "./SampleTestCaseTable";
import { HiClipboardList, HiUserCircle } from "react-icons/hi";
import { useGetLastFiveSubmissionsQuery } from "../../../graphql/generated.tsx";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CompilerQuestionCard = ({ question, loading, onApply, submitInput }) => {
  const [submissions, setSubmissions] = useState([]);
  const [expandedSubmissions, setExpandedSubmissions] = useState({});
  const [completedQuestions, setCompletedQuestion] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("studentData")) {
      setCompletedQuestion(
        JSON.parse(localStorage.getItem("studentData"))?.completedQuestions
      );
    }
  }, [localStorage.getItem("studentData")]);
  const { data } = useGetLastFiveSubmissionsQuery({
    variables: { submitInput: submitInput },
  });

  useEffect(() => {
    const fetchSubmissions = async () => {
      if (data?.getLastFiveSubmissions) {
        setSubmissions(data.getLastFiveSubmissions.submissions);
      }
    };

    fetchSubmissions();
  }, [data, question]);

  const onCopyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleSubmission = (index) => {
    setExpandedSubmissions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (loading) {
    return (
      <div className="shadow-md bg-white p-8 rounded-md items-center">
        Loading
      </div>
    );
  }

  const isCompleted = completedQuestions?.includes(question?.id);

  return (
    <Tabs aria-label="Tabs with underline" variant="fullWidth">
      <Tabs.Item active title="Question" icon={HiUserCircle}>
        <div
          className="relative shadow-md bg-white p-8 rounded-md"
          onCopy={onCopyHandler}
        >
          <h3 className="text-2xl font-semibold">{question?.title}</h3>
          <div
            className="py-2"
            dangerouslySetInnerHTML={{ __html: question?.text }}
          />
          <p className="mt-4 text-gray-600 dark:text-gray-400">
            {question?.question_sub_text}
          </p>

          {isCompleted && (
            <Badge color="green" className="absolute top-8 right-8">
              Completed
            </Badge>
          )}

          <h4 className="text-xl font-semibold mt-4">Sample test case:</h4>
          <div className="w-1/2">
            <SampleTestCaseTable question={question} />
          </div>
          <div className="mt-4">
            {question?.hints && question?.hints?.length !== 0 && (
              <span className="font-semibold text-gray-600 dark:text-gray-400">
                Hints:
              </span>
            )}
            <ul>
              {question?.hints?.length
                ? question.hints.map((hint, index) =>
                    hint.length > 0 ? (
                      <li key={index}>
                        Hint {index + 1}: {hint}
                      </li>
                    ) : null
                  )
                : null}
            </ul>
          </div>
        </div>
      </Tabs.Item>
      <Tabs.Item title="Submissions" icon={HiClipboardList}>
        <div className="relative shadow-md bg-white p-8 rounded-md">
          <h4 className="text-xl font-semibold mt-4">Last 5 Submissions:</h4>
          {submissions.length > 0 ? (
            submissions.map((submission, index) => (
              <div
                key={submission.id}
                className="p-4 border rounded-md my-2 cursor-pointer"
                onClick={() => toggleSubmission(index)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">Submission {index + 1}</p>
                    <p className="text-gray-600">Score: {submission.score}</p>
                    <p className="text-gray-600">
                      Submitted At:{" "}
                      {new Date(submission.submittedAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onApply(submission.code);
                      }}
                      className="p-0 bg-primary"
                    >
                      Apply
                    </Button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSubmission(index);
                      }}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      {expandedSubmissions[index] ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </button>
                  </div>
                </div>
                {expandedSubmissions[index] && (
                  <pre className="mt-2 bg-gray-100 p-2 rounded max-w-full overflow-x-auto">
                    {submission.code}
                  </pre>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-600">No submissions found.</p>
          )}
        </div>
      </Tabs.Item>
    </Tabs>
  );
};

export default CompilerQuestionCard;
