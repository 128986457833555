import React, { useEffect, useState } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import studentRoutes from "./Routes/StudentRoutes";
import adminRoutes from "./Routes/StaffsRoutes";
import publicRoutes from "./Routes/PublicRoutes";
import authRoutes from "./Routes/AuthRoutes";
import { auth } from "./utils/firebase";
import SuperAdminRoutes from "./Routes/SuperAdminRoutes";
import StudentOnboarding from "./Modules/Student/Screens/StudentOnboarding";
import Admin from "./Modules/Admin/Screens/Dashboards/SuperAdminDashboard";
import StaffOnboarding from "./Modules/Admin/Screens/Staffs/StaffOnboarding";
import StaffDashboard from "./Modules/Admin/Screens/Dashboards/StaffDashboard";
import { Spinner } from "flowbite-react";
import Assignments from "./Modules/Assignments/Screens/Assignments";
import HomePage from "./Modules/HomePage";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        user.getIdToken().then((token) => {
          localStorage.setItem("idtoken", token);
        });
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false once data is fetched
    });

    return () => {
      unsubscribe();
    };
  }, []);

  let userRole = null;
  let flogin = null;
  try {
    userRole = user?.reloadUserInfo?.customAttributes
      ? JSON?.parse(user?.reloadUserInfo?.customAttributes)?.role
      : null;
    flogin = JSON?.parse(user?.reloadUserInfo?.customAttributes)?.flogin;
  } catch (error) {
    console.error("Failed to parse user role:", error);
  }

  if (loading) {
    // Render a loading indicator or nothing while the user data is being fetched
    return (
      <div className="h-screen flex justify-center items-center">
         <Spinner size="xl" aria-label="Loading dashboard..." />
      </div>
    );
  }

  return (
    <div className="App w-full">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              userRole === "student" ? (
                user ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              ) : user ? (
                <Navigate to="/admin/courses" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/assignments"
            element={user ? <Assignments /> : <Navigate to="/login" />}
          />
          {publicRoutes}
          {authRoutes}
          {(userRole === "bt-admin" || userRole === "student") && studentRoutes}
          {(userRole === "bt-admin" || userRole === "staff") && adminRoutes}
          {userRole === "bt-admin" && SuperAdminRoutes}
          {userRole === "staff" && (
            <Route key="admin" path="/admin" element={<StaffDashboard />} />
          )}
          {userRole === "bt-admin" && (
            <Route key="admin" path="/admin" element={<Admin />} />
          )}
          {userRole === "student" && flogin && (
            <Route
              key="student-onboarding"
              path="student/student-onboarding/:id"
              element={<StudentOnboarding />}
            />
          )}
          {userRole === "staff" && flogin && (
            <Route
              key="update-details"
              path="admin/staff-onboarding/:id"
              element={<StaffOnboarding />}
            />
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
