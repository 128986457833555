import React, { useState, useEffect } from "react";
import AdminLayout from "../../Layout/index.jsx";
import jsFileDownload from "js-file-download";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFDocument } from "../../Components/PDFDocument.jsx";
import {
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Spinner,
  Alert,
  Modal,
  Dropdown,
  TextInput,
  Button,
} from "flowbite-react";
import {
  useGetClusterQuery,
  useGetLeaderboardQuery,
  useSendEmailMutation,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import formatDate from "../../Helpers/formatDate.jsx";
import { toast } from "react-toastify";
import AcSubmissionChart from "../../../Student/Components/AcSubmissionChart.jsx";
import Overlay from "../../../Student/Components/Overlay.jsx";
import StudentCourseProgression from "../../../Student/Components/StudentCourseProgression.jsx";
import { BsThreeDots } from "react-icons/bs";

const ClusterReport = () => {
  const { campusName, clusterId } = useParams();
  const [filter, setFilter] = useState("weekly");
  const [showEmailDropdown, setShowEmailDropdown] = useState(false);
  const [emails, setEmails] = useState("");
  const [emailError, setEmailError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState();
  const [openLeetcodeModal, setOpenLeetcodeModal] = useState(false);
  const [leetcodeData, setLeetCodeData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customFilterLoading, setcustomFilterLoading] = useState(false);

  const {
    data: ClusterData,
    loading: clusterLoading,
    error: clusterError,
  } = useGetClusterQuery({
    variables: { campusName, clusterId },
  });

  const {
    data: leaderboardData,
    loading: leaderboardLoading,
    error: leaderboardError,
    refetch,
  } = useGetLeaderboardQuery({
    variables: { campusName, clusterId, filter },
  });

  const [sendEmail, { loading }] = useSendEmailMutation();

  const createdAt = ClusterData?.getCluster?.createdAt;

  const handleFilter = (e) => {
    setFilter(e.target.value);
    if (e.target.value !== "custom") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDateChange = (e, type) => {
    if (type === "start") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };
  const applyFilter = async () => {
    setcustomFilterLoading(true);

    if (filter === "custom" && (!startDate || !endDate)) {
      alert("Please select both start and end dates.");
      setcustomFilterLoading(false); // Turn off loading when validation fails
      return;
    }

    try {
      await refetch({
        campusName,
        clusterId,
        filter: filter === "custom" ? null : filter,
        startDate: filter === "custom" ? startDate : null,
        endDate: filter === "custom" ? endDate : null,
      });
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setcustomFilterLoading(false);
    }
  };

  const downloadCSV = () => {
    if (!leaderboardData?.getLeaderboard) return;

    // Define the CSV header in the desired order
    const csvContent =
      "Position,Email,BT Platform Last Submissions Date,BT Points,Course Total Completed,Course Easy Completed,Course Medium Completed,Course Hard Completed,LeetCode Total Completed,LeetCode (Easy),LeetCode (Medium),LeetCode (Hard)\n" +
      leaderboardData.getLeaderboard
        .map((student) => {
          // Initialize LeetCode data with defaults
          let leetcodeEasy = 0;
          let leetcodeMedium = 0;
          let leetcodeHard = 0;
          let leetcodeTotal = 0;

          if (student.leetcode_data && student.leetcode_data.length > 0) {
            leetcodeEasy =
              student.leetcode_data.find((data) => data.difficulty === "Easy")
                ?.count || 0;
            leetcodeMedium =
              student.leetcode_data.find((data) => data.difficulty === "Medium")
                ?.count || 0;
            leetcodeHard =
              student.leetcode_data.find((data) => data.difficulty === "Hard")
                ?.count || 0;
            leetcodeTotal = leetcodeEasy + leetcodeMedium + leetcodeHard;
          }
          const courseEasy = student.easyQuestionsCompleted || 0;
          const courseMedium = student.mediumQuestionCompleted || 0;
          const courseHard = student.hardQuestionCompleted || 0;
          const courseTotal = student.completedQuestions;

          return `${student.position},${student.student.email},${
            student.latestSubmissionDate
              ? formatDate(student.latestSubmissionDate)
              : "Not yet started"
          },${
            student.totalScore
          },${courseTotal},${courseEasy},${courseMedium},${courseHard},${leetcodeTotal},${leetcodeEasy},${leetcodeMedium},${leetcodeHard}`;
        })
        .join("\n");

    const clusterName = ClusterData?.getCluster.name || "Cluster";
    const currentDate = formatDate(new Date());

    // Trigger the file download
    jsFileDownload(csvContent, `${clusterName}_${currentDate}.csv`);
  };

  const validateEmails = (emailString) => {
    const emailArray = emailString.split(",").map((email) => email.trim());
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    for (let email of emailArray) {
      if (!emailPattern.test(email)) {
        return false;
      }
    }
    return true;
  };

  const handleSendEmail = async () => {
    if (!validateEmails(emails)) {
      setEmailError("Please enter valid email addresses separated by commas.");
      return;
    }
    setEmailError("");

    const emailList = emails.split(",").map((email) => email.trim());

    const csvContent =
      "Position,Email,BT Platform Last Submissions Date,BT Points,Course Total Completed,Course Easy Completed,Course Medium Completed,Course Hard Completed,LeetCode Total Completed,LeetCode (Easy),LeetCode (Medium),LeetCode (Hard)\n" +
      leaderboardData.getLeaderboard
        .map((student) => {
          let leetcodeEasy = 0;
          let leetcodeMedium = 0;
          let leetcodeHard = 0;
          let leetcodeTotal = 0;

          if (student.leetcode_data && student.leetcode_data.length > 0) {
            leetcodeEasy =
              student.leetcode_data.find((data) => data.difficulty === "Easy")
                ?.count || 0;
            leetcodeMedium =
              student.leetcode_data.find((data) => data.difficulty === "Medium")
                ?.count || 0;
            leetcodeHard =
              student.leetcode_data.find((data) => data.difficulty === "Hard")
                ?.count || 0;
            leetcodeTotal = leetcodeEasy + leetcodeMedium + leetcodeHard;
          }

          const courseEasy = student.easyQuestionsCompleted || 0;
          const courseMedium = student.mediumQuestionCompleted || 0;
          const courseHard = student.hardQuestionCompleted || 0;
          const courseTotal = student.completedQuestions;

          return `${student.position},${student.student.email},${
            student.latestSubmissionDate
              ? formatDate(student.latestSubmissionDate)
              : "Not yet started"
          },${
            student.totalScore
          },${courseTotal},${courseEasy},${courseMedium},${courseHard},${leetcodeTotal},${leetcodeEasy},${leetcodeMedium},${leetcodeHard}`;
        })
        .join("\n");

    try {
      await sendEmail({
        variables: {
          emailInput: {
            emailList,
            csvContent,
            assignmentName: ClusterData?.getCluster.name,
          },
        },
      });
      toast.success("Email sent successfully.");
      setShowEmailDropdown(false);
      setEmails("");
    } catch (error) {
      toast.error(error.message || "Failed to send email.");
    }
  };

  if (clusterLoading || leaderboardLoading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner aria-label="Loading" />
        </div>
      </AdminLayout>
    );
  }

  if (clusterError || leaderboardError) {
    return (
      <AdminLayout>
        <div className="p-10">
          <Alert color="failure">
            <span>
              <p>
                Error fetching data:{" "}
                {clusterError?.message || leaderboardError?.message}
              </p>
            </span>
          </Alert>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="bg-white mt-5 w-full rounded-lg shadow-lg">
          <div className="flex flex-col md:flex-row items-center justify-between p-5 border-b">
            <div className="flex flex-col">
              <h2 className="text-2xl font-bold">
                {ClusterData?.getCluster?.name || "Cluster"} Overview
              </h2>
              <p className="text-sm mt-2 text-gray-500">
                Created At: {formatDate(createdAt)}
              </p>
            </div>
            <h4 className="text-lg">
              <span className="font-semibold">No Of Students:</span>{" "}
              {ClusterData?.getCluster?.studentCount || 0}
            </h4>
          </div>
          <div className="p-5">
            <div className="flex items-center justify-between mb-5">
              <Select id="filter" value={filter} onChange={handleFilter}>
                <option value="weekly">Weekly</option>
                <option value="daily">Today</option>
                <option value="past10days">Past 10 Days</option>
                <option value="monthly">Monthly</option>
                <option value="custom">Custom Date</option>
              </Select>
              {filter === "custom" && (
                <div className="flex gap-5">
                  <TextInput
                    type="date"
                    value={startDate}
                    onChange={(e) => handleDateChange(e, "start")}
                  />
                  <TextInput
                    type="date"
                    value={endDate}
                    onChange={(e) => handleDateChange(e, "end")}
                  />
                  <Button
                    onClick={applyFilter}
                    disabled={customFilterLoading}
                    className="flex items-center justify-center"
                  >
                    {" "}
                    {customFilterLoading && (
                      <Spinner
                        aria-label="Loading"
                        size={"sm"}
                        className="mr-2"
                      />
                    )}
                    Apply
                  </Button>
                </div>
              )}

              <div className="relative">
                <button
                  onClick={downloadCSV}
                  className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Download CSV
                </button>
                <PDFDownloadLink
                  document={
                    <PDFDocument
                      students={leaderboardData?.getLeaderboard}
                      ClusterName={ClusterData?.getCluster?.name}
                      CreatedAt={formatDate(createdAt)}
                    />
                  }
                  fileName={`${ClusterData?.getCluster.name}_${formatDate(
                    new Date()
                  )}.pdf`}
                >
                  {({ loading }) => (
                    <button className="mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                      {loading ? "Loading document..." : "Download PDF"}
                    </button>
                  )}
                </PDFDownloadLink>
                <button
                  onClick={() => setShowEmailDropdown(!showEmailDropdown)}
                  className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
                >
                  Share Now
                </button>
                {showEmailDropdown && (
                  <div className="absolute right-0 mt-2 w-[350px] bg-white shadow-lg p-4 rounded-lg z-10">
                    <textarea
                      className="w-full p-2 border rounded-lg"
                      placeholder="Enter email addresses separated by commas"
                      value={emails}
                      onChange={(e) => setEmails(e.target.value)}
                    />
                    {emailError && (
                      <p className="text-red-500 text-sm mt-2">{emailError}</p>
                    )}
                    <button
                      disabled={loading}
                      onClick={handleSendEmail}
                      className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full flex item-center gap-2 justify-center"
                    >
                      {loading && <Spinner size={"sm"} />}Send Now
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5">
              {leaderboardData?.getLeaderboard.length === 0 ? (
                <p>No data available for the selected filter.</p>
              ) : (
                <>
                  <Table className="min-w-full bg-white">
                    <TableHead>
                      <TableHeadCell className="py-2 px-4 border-b">
                        Email
                      </TableHeadCell>
                      <TableHeadCell className="py-2 px-4 border-b text-center">
                        Points
                      </TableHeadCell>
                      <TableHeadCell className="py-2 px-4 border-b text-center">
                        Course Last <br />
                        Submission
                      </TableHeadCell>
                      <TableHeadCell className="py-2 px-4 border-b text-center">
                        Course Questions <br />
                        Completed
                      </TableHeadCell>
                      <TableHeadCell className="py-2 px-4 border-b text-center">
                        Leetcode Questions <br />
                        Completed
                      </TableHeadCell>
                      <TableHeadCell className="py-2 px-4 border-b text-center">
                        View More
                      </TableHeadCell>
                    </TableHead>
                    <TableBody>
                      {leaderboardData?.getLeaderboard.map((student, index) => {
                        let leetcodeStatus = "Invalid Leetcode Username";
                        let leetcodeEasy = 0;
                        let leetcodeMedium = 0;
                        let leetcodeHard = 0;

                        if (
                          student.leetcode_data &&
                          student.leetcode_data.length > 0
                        ) {
                          leetcodeStatus = "Valid";
                          leetcodeEasy =
                            student.leetcode_data.find(
                              (data) => data.difficulty === "Easy"
                            )?.count || 0;
                          leetcodeMedium =
                            student.leetcode_data.find(
                              (data) => data.difficulty === "Medium"
                            )?.count || 0;
                          leetcodeHard =
                            student.leetcode_data.find(
                              (data) => data.difficulty === "Hard"
                            )?.count || 0;
                        }

                        return (
                          <TableRow key={index} className="hover:bg-gray-100">
                            <TableCell className="py-2 px-4 border-b">
                              {student.student.email}
                            </TableCell>
                            <TableCell className="py-2 px-4 border-b text-center">
                              {student.totalScore}
                            </TableCell>
                            <TableCell className="py-2 px-4 border-b text-center">
                              {student.latestSubmissionDate
                                ? formatDate(student.latestSubmissionDate)
                                : "-"}
                            </TableCell>
                            <TableCell className="py-2 px-4 border-b text-center">
                              {student.completedQuestions}
                            </TableCell>
                            <TableCell className="py-2 px-4 border-b text-center">
                              {leetcodeEasy + leetcodeHard + leetcodeMedium}
                            </TableCell>
                            <TableCell className="border-b text-center flex justify-center">
                              <Dropdown
                                arrowIcon={false}
                                inline={true}
                                className="w-44"
                                placement="bottom"
                                label={<BsThreeDots />}
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    setOpenModal(true);
                                    setSelectedStudent(student.student);
                                  }}
                                >
                                  Course Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setOpenLeetcodeModal(true);
                                    setLeetCodeData(student.leetcode_data);
                                  }}
                                >
                                  LeetCode
                                </Dropdown.Item>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>

                  <Modal
                    show={openLeetcodeModal}
                    onClose={() => setOpenLeetcodeModal(false)}
                    className="w-6xl"
                  >
                    {" "}
                    {/* Custom width */}
                    <Modal.Header>LeetCode Progress</Modal.Header>
                    <Modal.Body>
                      <AcSubmissionChart
                        isLoading={leaderboardLoading}
                        acSubmissionNum={leetcodeData}
                      />
                    </Modal.Body>
                  </Modal>

                  {/* Modal placed outside the loop */}
                  <Modal
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    className="w-6xl"
                  >
                    {" "}
                    {/* Custom width */}
                    <Modal.Header>Overall Course Progress</Modal.Header>
                    <Modal.Body>
                      <StudentCourseProgression studentData={selectedStudent} />
                      {!selectedStudent && (
                        <Overlay message="Course progress data not available" />
                      )}
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ClusterReport;
