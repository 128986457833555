import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Spinner, Table } from "flowbite-react";
import {
  useGetClusterQuery,
  useGetLeaderboardQuery,
} from "../../../graphql/generated.tsx";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const LeaderboardStaff = ({ clusterId, campusName }) => {
  const [filter, setFilter] = useState("weekly");
  const navigate = useNavigate()

  const {
    data: leaderboardData,
    loading: leaderboardLoading,
    error: leaderboardError,
    refetch,
  } = useGetLeaderboardQuery({
    variables: { campusName, clusterId, filter },
  });

  const {
    data: clusterData,
    loading: clusterLoading,
    error: clusterError,
  } = useGetClusterQuery({
    variables: { campusName, clusterId },
  });

  useEffect(() => {
    refetch({ campusName, clusterId, filter });
  }, [filter, campusName, clusterId, refetch]);

  const handleFilter = (value) => {
    setFilter(value);
  };

  if (leaderboardLoading || clusterLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (leaderboardError || clusterError) {
    return (
      <div className="text-center text-red-600">
        <p>
          Error:{" "}
          {leaderboardError ? leaderboardError.message : clusterError.message}
        </p>
      </div>
    );
  }

  const Datepicker = () => {
    return (
      <span className="text-sm text-gray-600">
        <Dropdown inline label={filter}>
          <Dropdown.Item onClick={() => handleFilter("weekly")}>
            <strong>Weekly</strong>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleFilter("daily")}>
            Today
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilter("past10days")}>
            Past 10 Days
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilter("monthly")}>
            Monthly
          </Dropdown.Item>
        </Dropdown>
      </span>
    );
  };

  return (
    <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8 my-5">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
            {clusterData?.getCluster?.name}'s Leaderboard
          </h3>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow sm:rounded-lg">
              <Table
                className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 border"
              >
                <Table.Head className="bg-gray-50 dark:bg-gray-700">
                  <Table.HeadCell>Email</Table.HeadCell>
                  <Table.HeadCell>Points</Table.HeadCell>
                  <Table.HeadCell>Last Submission</Table.HeadCell>
                  <Table.HeadCell>Questions Completed</Table.HeadCell>
                </Table.Head>
                <Table.Body className="bg-white dark:bg-gray-800">
                  {leaderboardData?.getLeaderboard?.length > 0 ? (
                    leaderboardData.getLeaderboard.map((student, index) => (
                      <Table.Row key={index} className="hover:bg-gray-50" onClick={()=> navigate(`/admin/student-details/${student.student_id}`)}>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                          {student.student.email}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-500 dark:text-gray-400">
                          {student.totalScore}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-semibold text-gray-900 dark:text-white">
                          {formatDate(student.latestSubmissionDate)}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                          {student.student.completedQuestions?.length || 0}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell
                        colSpan="4"
                        className="text-center text-sm font-normal text-gray-500 dark:text-gray-400"
                      >
                        No data available
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-3 sm:pt-6">
        <Datepicker />
        {/* <div className="shrink-0">
          <Link
            to={`/practice/${clusterId}`}
            className="inline-flex items-center rounded-lg p-2 text-xs font-medium uppercase text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm"
          >
            Continue to Courses
            <svg
              className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default LeaderboardStaff;
