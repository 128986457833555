import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { Card } from "flowbite-react";
import { useGetAllAssignementsQuery } from "../../../../graphql/generated.tsx";
import { toast } from "react-toastify";

const Assignments = () => {
  const [assignments, setAssignments] = useState([]);
  const { data, loading } = useGetAllAssignementsQuery();

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        if (data?.getAllAssignements) {
          setAssignments(data?.getAllAssignements);
        }
      } catch (error) {
        toast.error("Error in fetching !");
      }
    };

    fetchAssignments();
  }, [data]);

  return (
    <AdminLayout>
      <div className="p-8 bg-slate-100 min-h-screen">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              All Assignments
            </div>
          </div>
          <Link
            to="/admin/assignments/create-assignment"
            className="bg-primary text-white lg:px-3 lg:py-1 rounded-md max-md:py-1 max-md:px-2 max-md:ml-1 flex"
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Create Assignment</span>
          </Link>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
          </div>
        ) : assignments.length === 0 ? (
          <div className="flex justify-center items-center h-screen">
            <p className="text-gray-500">No assignments found.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {assignments.map((assignment) => (
              <Link to={`/admin/assignments/${assignment.id}`}>
                <Card
                  key={assignment.id}
                  className="max-w-sm transform transition-transform hover:scale-105 min-h-[300px]"
                >
                  <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {assignment.name}
                  </h5>
                  <p className="font-normal text-gray-700 dark:text-gray-400">
                    {assignment.short_description}
                  </p>
                </Card>
              </Link>
            ))}
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default Assignments;
