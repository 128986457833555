import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { abcdef } from "@uiw/codemirror-theme-abcdef";
import { androidstudio } from "@uiw/codemirror-theme-androidstudio";
import { sublime } from "@uiw/codemirror-theme-sublime";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button, Spinner, Tooltip } from "flowbite-react";
import { EditorView } from "@codemirror/view";
import { useSaveAutoSaveMutation } from "../../../graphql/generated.tsx";

const Compiler = ({
  language,
  setLanguage,
  setToDefault,
  handleCodeChange,
  errorRef,
  handleSubmit,
  code,
  handleRun,
  CourseData,
  compileLoading,
  submitLoading,
  question_id,
  student_id,
}) => {
  const themes = [
    { label: "Dracula", value: dracula },
    { label: "ABCDEF", value: abcdef },
    { label: "Android Studio", value: androidstudio },
    { label: "Sublime", value: sublime },
  ];

  const [autoSave, { loading }] = useSaveAutoSaveMutation();
  const [selectedTheme, setSelectedTheme] = useState(dracula);
  const [languageExtensions, setLanguageExtensions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [disableCopyPaste, setDisableCopyPaste] = useState([]);
  const [lastSubmittedCode, setLastSubmittedCode] = useState("");
  const [hasCodeChanged, setHasCodeChanged] = useState(true);

  useEffect(() => {
    const loadLanguageExtensions = async () => {
      let extensions = [];
      switch (language) {
        case "CPP":
        case "C":
          const { cpp } = await import("@codemirror/lang-cpp");
          extensions.push(cpp());
          break;
        case "PYTHON":
          const { python } = await import("@codemirror/lang-python");
          extensions.push(python());
          break;
        case "JAVA":
          const { java } = await import("@codemirror/lang-java");
          extensions.push(java());
          break;
        default:
          break;
      }
      setLanguageExtensions(extensions);
    };
    loadLanguageExtensions();
  }, [language]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      handleSaveCode();
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [code, language]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleSaveCode(); // Auto-save every 2 minutes
    }, 120000);

    return () => clearInterval(interval);
  }, [autoSave, question_id, student_id, code, language]);

  const handleSaveCode = () => {
    setIsSaving(true);
    autoSave({
      variables: {
        question_id,
        student_id,
        code,
        language,
      },
    })
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Save code error:", error);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (code !== lastSubmittedCode) {
      setHasCodeChanged(true); 
    } else {
      setHasCodeChanged(false); 
    }
  }, [code, lastSubmittedCode]);

  const handleRunClick = () => {
    if (hasCodeChanged) {
      handleRun(); 
      setLastSubmittedCode(code); 
      setHasCodeChanged(false); 
    }
  };

  useEffect(() => {
    if (
      student_id === "e236fa5f-cadf-4cbb-8a1b-4b7445150b24" ||
      student_id === "2997a790-1422-426e-ba0d-79cad583e803" ||
      student_id === "e58f8d65-a5fa-4f94-8473-cf3d8f3b4be2"
    ) {
      setDisableCopyPaste([]);
    } else {
      const handlers = EditorView.domEventHandlers({
        keydown: (event) => {
          if (
            event.ctrlKey &&
            (event.key === "c" || event.key === "v" || event.key === "x")
          ) {
            event.preventDefault();
          }
        },
        copy: (event) => event.preventDefault(),
        paste: (event) => event.preventDefault(),
        cut: (event) => event.preventDefault(),
      });
      setDisableCopyPaste([handlers]);
    }
  }, [student_id]);

  return (
    <>
      <div className="w-full bg-gray-100 shadow-lg flex items-center rounded-t-lg justify-between p-5 gap-x-4">
        <FormControl className="w-[100px]">
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            value={language || "JAVA"}
            label="Language"
            onChange={(e) => setLanguage(e.target.value)}
          >
            {CourseData?.languages?.map((lang) => (
              <MenuItem key={lang} value={lang.toUpperCase()}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="w-[200px] max-md:w-[100px]">
          <InputLabel id="theme-select-label">Theme</InputLabel>
          <Select
            labelId="theme-select-label"
            value={selectedTheme}
            label="Theme"
            onChange={(e) => setSelectedTheme(e.target.value)}
          >
            {themes.map((theme) => (
              <MenuItem key={theme.label} value={theme.value}>
                {theme.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={setToDefault}
          className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
        >
          Reset Code
        </Button>
        <Button
          className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
          onClick={handleSaveCode}
        >
          {loading || isSaving ? (
            <>
              <Spinner
                aria-label="Medium sized spinner example"
                size="sm"
                className="mr-2"
              />
              Saving
            </>
          ) : (
            "Save Code"
          )}
        </Button>
      </div>

      <div className="flex flex-col">
        <div className="w-full">
          <CodeMirror
            value={code}
            height="600px"
            width="100%"
            theme={selectedTheme}
            extensions={[...languageExtensions, ...disableCopyPaste]}
            options={{
              mode: "text/x-csrc",
              lineNumbers: true,
              readOnly: "nocursor",
            }}
            onChange={handleCodeChange}
          />
        </div>
      </div>

      <div ref={errorRef} className="flex "></div>
      <div className="mt-4 flex gap-2 justify-end">
        {!hasCodeChanged && !compileLoading ? (
          <Tooltip content="Edit your code to enable">
            <Button
              className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
              onClick={handleRunClick}
              disabled={!hasCodeChanged || compileLoading}
            >
              {compileLoading && (
                <Spinner
                  aria-label="Medium sized spinner example"
                  size="sm"
                  className="mr-2"
                />
              )}
              Run
            </Button>
          </Tooltip>
        ) : (
          <Button
            className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
            onClick={handleRunClick}
            disabled={!hasCodeChanged || compileLoading}
          >
            {compileLoading && (
              <Spinner
                aria-label="Medium sized spinner example"
                size="sm"
                className="mr-2"
              />
            )}
            Run
          </Button>
        )}

        <Button
          className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
          onClick={handleSubmit}
        >
          {submitLoading && (
            <Spinner
              aria-label="Medium sized spinner example"
              size="sm"
              className="mr-2"
            />
          )}
          Submit
        </Button>
      </div>
    </>
  );
};

export default Compiler;
