import React, { useEffect, useState } from "react";
import {
  useAddClusterToStudentsMutation,
  useGetStudentsByCampusQuery,
  useGetStudentsByClusterQuery,
  useRemoveClusterFromStudentsMutation,
} from "../../../../graphql/generated.tsx";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { Button, Modal, Table } from "flowbite-react";
import { BiImport } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import AdminLayout from "../../Layout/index.jsx";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const StudentsTab = ({ campusId, clusterId, ClusterData, campusName }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setPage(1); // Reset page to 1 when search term changes
  }, [searchTerm]);

  const toggleAddStudentModal = () => {
    setShowAddStudentModal(!showAddStudentModal);
  };
  const { data: AllStudentsByCampus } = useGetStudentsByCampusQuery({
    variables: { campusId },
    skip: !campusId,
  });
  const [addClusterToStudent] = useAddClusterToStudentsMutation();
  const [removeClusterFromStudents] = useRemoveClusterFromStudentsMutation(); // Initialize the mutation

  const { error: studentError } = useGetStudentsByClusterQuery({
    variables: { clusterId, searchTerm, page, limit },
    onCompleted: (data) => setStudents(data.getStudentsByCluster),
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleAddStudents = async (e) => {
    e.preventDefault();
    const { data: AddClusterId } = await addClusterToStudent({
      variables: {
        input: {
          clusterId,
          studentIds: selectedStudents.map((c) => c.value),
          courseIds: ClusterData?.getCluster.courseIds,
          campusName,
        },
      },
    });
    setStudents((prev) => [...prev, ...AddClusterId.addClusterToStudents]);
    setShowAddStudentModal(false);
    setSelectedStudents("");
    toast.success("Students added successfully");
  };

  const handleSelectStudent = (studentId) => {
    if (selectedStudentIds.includes(studentId)) {
      setSelectedStudentIds(
        selectedStudentIds.filter((id) => id !== studentId)
      );
    } else {
      setSelectedStudentIds([...selectedStudentIds, studentId]);
    }
  };

  const handleDeleteStudents = async (singleStudent) => {
    const studentIdsToDelete = selectedStudentIds
      ? selectedStudentIds
      : singleStudent;
      console.log({ selectedStudentIds });
    try {
      await removeClusterFromStudents({
        variables: {
          input: {
            clusterId,
            studentIds: studentIdsToDelete,
            courseIds: ClusterData?.getCluster.courseIds,
            campusName,
          },
        },
      });
      setStudents((prev) =>
        prev.filter((student) => !studentIdsToDelete.includes(student.id))
      );
      setSelectedStudentIds([]);
      toast.success("Students removed successfully");
    } catch (error) {
      console.error("Error removing students:", error);
    }
  };
  const studentOptions = AllStudentsByCampus?.getStudentsByCampus.students.map(
    (student) => ({
      label: student.email,
      value: student.id,
    })
  );

  if (studentError)
    return <AdminLayout>Error: {studentError.message}</AdminLayout>;
  return (
    <>
      <div className="p-4">
        <div className="flex justify-between items-center mb-5">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by name or email"
            className="border border-gray-300 rounded px-3 py-1 mb-3"
          />
          <div className="w-1/3 flex justify-between">
            <Link to={`/admin/students/${campusId}/create-students`}>
              <Button className="text-white p-0 bg-primary">
                <IoMdAdd className="mr-1 mt-1" /> <span>Create Student</span>
              </Button>
            </Link>

            <Button
              onClick={toggleAddStudentModal}
              className="text-white p-0 bg-primary"
            >
              <BiImport className="mr-1 mt-1" /> <span>Add Student</span>
            </Button>
          </div>
        </div>

        <Table className="min-w-full bg-white border border-gray-300">
          <Table.Head>
            <Table.HeadCell>
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedStudentIds(students.map((s) => s.id));
                  } else {
                    setSelectedStudentIds([]);
                  }
                }}
                checked={
                  students.length > 0 &&
                  selectedStudentIds.length === students.length
                }
              />
            </Table.HeadCell>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Phone Number</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
            {/* Add more table headers as needed */}
          </Table.Head>
          <Table.Body>
            {students?.map((student) => (
              <Table.Row
                key={student.id}
                // onClick={() => navigate(`/admin/student-details/${student.id}`)}
                className="cursor-pointer"
              >
                <Table.Cell>
                  <input
                    type="checkbox"
                    checked={selectedStudentIds.includes(student.id)}
                    onChange={() => handleSelectStudent(student.id)}
                  />
                </Table.Cell>
                <Table.Cell>{student.name}</Table.Cell>
                <Table.Cell>{student.email}</Table.Cell>
                <Table.Cell>{student.phno}</Table.Cell>
                <Table.Cell>
                  <MdDelete
                    onClick={() => handleDeleteStudents([student.id])}
                    className="h-7 w-7 ml-2 hover:bg-red-500 rounded-full cursor-pointer p-1"
                  />
                </Table.Cell>
                {/* Add more table cells as needed */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="flex justify-between mt-4">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded"
          >
            Previous
          </button>
          <span>{page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={students.length < limit}
            className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded"
          >
            Next
          </button>
        </div>
        {selectedStudentIds.length > 0 && (
          <Button
            className="mt-4 text-white bg-red-500"
            onClick={handleDeleteStudents}
          >
            Delete Selected
          </Button>
        )}
      </div>
      <Modal show={showAddStudentModal} onClose={toggleAddStudentModal}>
        <Modal.Header>Add Students</Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => handleAddStudents(e)}>
            <div className="mb-4 h-[200px]">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Students
              </label>
              <Select
                options={studentOptions}
                isMulti
                value={selectedStudents}
                onChange={setSelectedStudents}
                placeholder="Select students"
              />
            </div>
            <div className="flex items-center justify-between">
              <Button type="submit" className="p-0 bg-primary">
                Add
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StudentsTab;
