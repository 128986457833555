import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Spinner, Table } from "flowbite-react";
import {
  useGetClusterQuery,
  useGetLeaderboardQuery,
} from "../../../graphql/generated.tsx";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import { Link } from "react-router-dom";
import { FaMedal } from "react-icons/fa"; // Importing FontAwesome icons

const Leaderboard = ({ clusterId, campusName }) => {
  const {
    data: leaderboardData,
    loading: leaderboardLoading,
    error: leaderboardError,
    refetch,
  } = useGetLeaderboardQuery({
    variables: { campusName, clusterId,filter: "custom",limit: 10 },
  });

  const {
    data: clusterData,
    loading: clusterLoading,
    error: clusterError,
  } = useGetClusterQuery({
    variables: { campusName, clusterId },
  });

  useEffect(() => {
    refetch({ campusName, clusterId });
  }, [campusName, clusterId, refetch]);

  if (leaderboardLoading || clusterLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (leaderboardError || clusterError) {
    return (
      <div className="text-center text-red-600">
        <p>
          Error:{" "}
          {leaderboardError ? leaderboardError.message : clusterError.message}
        </p>
      </div>
    );
  }

  const leaderboard = leaderboardData.getLeaderboard;

  const getPositionIcon = (position) => {
    switch (position) {
      case 1:
        return (
          <div className="flex items-center gap-2 justify-center">
            1 <FaMedal className="text-yellow-400" />
          </div>
        );
      case 2:
        return (
          <div className="flex items-center gap-2 justify-center">
            2 <FaMedal className="text-gray-400" />
          </div>
        );
      case 3:
        return (
          <div className="flex items-center gap-2 justify-center">
            3 <FaMedal className="text-orange-400" />
          </div>
        );
      default:
        return position;
    }
  };

  return (
    <>
      {leaderboard.length > 0 ? (
        <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8 my-10">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                {clusterData?.getCluster?.name}'s Top 10 Leaderboard
              </h3>
            </div>
          </div>
          <div className="overflow-x-auto">
            <Table striped className="min-w-full">
              <Table.Head className="bg-primary">
                <Table.HeadCell className="bg-primary text-white">
                  Position
                </Table.HeadCell>
                <Table.HeadCell className="bg-primary text-white text-center">
                  Name
                </Table.HeadCell>
                <Table.HeadCell className="bg-primary text-white text-center">
                  Email
                </Table.HeadCell>
                <Table.HeadCell className="bg-primary text-white text-center">
                  Points
                </Table.HeadCell>
                <Table.HeadCell className="bg-primary text-white text-center">
                  Last Submission
                </Table.HeadCell>
                <Table.HeadCell className="bg-primary text-white text-center">
                  Questions Completed
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="bg-white dark:bg-gray-800">
                {leaderboard.length > 0 ? (
                  leaderboard.map((student, index) =>
                    student.student ? (
                      <Table.Row key={index}>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white text-center">
                          {getPositionIcon(student.position)}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white text-center">
                          {student.student.name}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white text-center">
                          {student.student.email}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-500 dark:text-gray-400 text-center">
                          {student.totalScore}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-semibold text-gray-900 dark:text-white text-center">
                          {formatDate(student.latestSubmissionDate)}
                        </Table.Cell>
                        <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white text-center">
                          {student.student.completedQuestions.length|| 0}
                        </Table.Cell>
                      </Table.Row>
                    ) : null
                  )
                ) : (
                  <Table.Row>
                    <Table.Cell
                      colSpan="5"
                      className="text-center text-sm font-normal text-gray-500 dark:text-gray-400"
                    >
                      No data available
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between pt-3 sm:pt-6">
            <div className="shrink-0 mb-2 sm:mb-0">
              <Link
                to={`/student/courses/${clusterId}`}
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium uppercase text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm"
              >
                Continue to Courses
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Leaderboard;
