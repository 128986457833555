import React, { useState, useEffect, useCallback } from "react";
import { auth } from "../../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Label, TextInput } from "flowbite-react";
import NavBar from "../../Common/NavBar";
import {
  useGetCampusQuery,
  useGetStaffByEmailIdLazyQuery,
  useGetStudentByEmailIdLazyQuery,
} from "../../graphql/generated.tsx";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [getStaffByEmailId, { data: StaffData }] =
    useGetStaffByEmailIdLazyQuery();
  const [getStudentByEmailId, { data: StudentData }] =
    useGetStudentByEmailIdLazyQuery();

  const { data: campusDetails } = useGetCampusQuery({
    variables: {
      id: StudentData
        ? StudentData?.getStudentByEmailId.campusId
        : StaffData?.getStaffByEmailId.campusId,
    },
    skip: !StudentData && !StaffData, // Skip query if neither StaffData nor StudentData is available
  });

  const fetchStaffAndStudentData = useCallback(
    debounce((email) => {
      getStaffByEmailId({ variables: { email } });
      getStudentByEmailId({ variables: { email } });
    }, 500), // 500ms debounce time
    []
  );

  useEffect(() => {
    if (email) {
      fetchStaffAndStudentData(email);
    }
  }, [email, fetchStaffAndStudentData]);

  useEffect(() => {
    if (!isLoading) return;

    const fetchData = async () => {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        localStorage.setItem("user", JSON.stringify(user));
        const idTokenResult = await user.getIdTokenResult();
        const { claims } = idTokenResult;

        if (claims.role === "student" && StudentData) {
          localStorage.setItem(
            "studentData",
            JSON.stringify(StudentData.getStudentByEmailId)
          );
        } else if (claims.role === "staff" && StaffData) {
          localStorage.setItem(
            "staffData",
            JSON.stringify(StaffData.getStaffByEmailId)
          );
        }

        if (campusDetails) {
          localStorage.setItem(
            "campusDetails",
            JSON.stringify(campusDetails.campus)
          );
        }

        if (claims.flogin === false) {
          navigate("/");
        } else {
          if (claims.role === "staff") {
            navigate(
              `/admin/staff-onboarding/${StaffData.getStaffByEmailId.id}`
            );
          } else if (claims.role === "student") {
            navigate(
              `/student/student-onboarding/${StudentData.getStudentByEmailId.id}`
            );
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error("Invalid Email or Password");
        console.log(errorCode, errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    isLoading,
    email,
    password,
    StaffData,
    StudentData,
    campusDetails,
    navigate,
  ]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submitLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
  };

  return (
    <>
      <NavBar />
      <div className="flex flex-col md:flex-row min-h-screen min-w-screen justify-between px-8 md:px-28 py-12 mx-auto bg-slate-100 items-center">
        <div className="hidden md:block w-1/2 pr-10">
          <img
            src="/developer-team.svg"
            alt="Your Company"
            className="w-full h-auto"
          />
        </div>

        <div className="bg-white w-full md:w-[400px] p-10 h-auto md:h-1/2 rounded-lg max-md:my-auto">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto"
              src="/favicon.ico"
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={submitLogin}>
              <div>
                <Label htmlFor="email2" value="User email" />
                <div className="mt-2">
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    required={true}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="password2" value="Password" />
                  <div className="text-sm">
                    <a
                      href="/forgetPassword"
                      className="font-semibold text-primary hover:text-indigo-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <TextInput
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
