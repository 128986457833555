import React from "react";
import NavBar from "../../../Common/NavBar";
import LeftSideBar from "../Components/Sidebar";

const StudentLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div className="flex items-start pt-14">
        <LeftSideBar />
        <main className="w-full min-h-screen bg-slate-100 max-md:ml-10">
          {children}
        </main>
      </div>
    </>
  );
};

export default StudentLayout;
