import React, { useState, useEffect } from "react";

const Timer = ({ durationInHours, onTimeUp }) => {
  const durationInSeconds = durationInHours * 3600;
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    const savedStartTime = localStorage.getItem("timerStartTime");
    const savedDuration = localStorage.getItem("timerDuration");

    if (savedStartTime && savedDuration) {
      const startTime = new Date(savedStartTime);
      const parsedDuration = parseFloat(savedDuration);

      if (!isNaN(parsedDuration) && parsedDuration > 0) {
        const elapsedTime = (Date.now() - startTime.getTime()) / 1000;
        const remainingTime = parsedDuration - elapsedTime;

        if (remainingTime > 0) {
          setTimeLeft(remainingTime);
          setIsRunning(true);
        } else {
          localStorage.removeItem("timerStartTime");
          localStorage.removeItem("timerDuration");
          startTimer();
        }
      } else {
        // Invalid duration or tampered data, reset timer
        localStorage.removeItem("timerStartTime");
        localStorage.removeItem("timerDuration");
        startTimer();
      }
    } else {
      startTimer();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            localStorage.removeItem("timerStartTime");
            localStorage.removeItem("timerDuration");
            setIsRunning(false);
            onTimeUp(); // Call the onTimeUp function when time is up
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, onTimeUp]);

  const startTimer = () => {
    const startTime = new Date();
    localStorage.setItem("timerStartTime", startTime.toString());
    localStorage.setItem("timerDuration", durationInSeconds.toString());
    setTimeLeft(durationInSeconds);
    setIsRunning(true);
  };

  return (
    <div>
      <h1>
        Time Left: {Math.floor(timeLeft / 3600)} hours{" "}
        {Math.floor((timeLeft % 3600) / 60)} minutes {Math.floor(timeLeft % 60)}{" "}
        seconds
      </h1>
    </div>
  );
};

export default Timer;
