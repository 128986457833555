import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import { useGetStudentSubmissionsForTimeRangeQuery } from "../../../graphql/generated.tsx";
import { Spinner } from "flowbite-react";
import { GrCircleInformation } from "react-icons/gr";

const timeRanges = {
  LAST_MONTH: 1,
  LAST_3_MONTHS: 3,
  LAST_6_MONTHS: 6,
};

const years = Array.from(
  new Array(10),
  (val, index) => new Date().getFullYear() - index
);

const DailyActivityGraph = ({ studentId, campusName }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRanges.LAST_MONTH
  );

  const { data, loading, refetch } = useGetStudentSubmissionsForTimeRangeQuery({
    variables: {
      studentId,
      campusName,
      timeRangeInMonths: selectedTimeRange,
    },
  });

  useEffect(() => {
    // Trigger a refetch every time the selectedTimeRange changes
    refetch();
  }, [selectedTimeRange, refetch]);

const calculateDataForTimeRange = (data) => {
  const submissionsByDateAndCourse = {};

  // Iterate through the data array, which contains submission objects for each course
  (data?.getStudentSubmissionsForTimeRange || []).forEach((submissionData) => {
    const { courseName, date, submissions } = submissionData;
    const entryDate = new Date(date);

    // Format the date as dd/mm/yy
    const formattedDate = entryDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    // Initialize the course if it doesn't exist in the object
    if (!submissionsByDateAndCourse[courseName]) {
      submissionsByDateAndCourse[courseName] = {};
    }

    // Initialize the date entry if it doesn't exist
    if (!submissionsByDateAndCourse[courseName][formattedDate]) {
      submissionsByDateAndCourse[courseName][formattedDate] = 0;
    }

    // Add the submission count
    submissionsByDateAndCourse[courseName][formattedDate] += submissions.length;
  });

  // Prepare submission data in an array format suitable for the graph
  const submissionData = Object.keys(submissionsByDateAndCourse).map(
    (courseName) => {
      return {
        courseName,
        dates: Object.keys(submissionsByDateAndCourse[courseName]).map(
          (date) => ({
            date,
            submissions: submissionsByDateAndCourse[courseName][date],
          })
        ),
      };
    }
  );

  // Sort the data by date for each course
  submissionData.forEach((courseData) => {
    courseData.dates.sort((a, b) => {
      const dateA = new Date(a.date.split("/").reverse().join("/"));
      const dateB = new Date(b.date.split("/").reverse().join("/"));
      return dateA - dateB; // Sort ascending
    });
  });

  return submissionData;
};



  const submissionData = calculateDataForTimeRange(data);

  const colors = [
    "#284659", // Bright Light Blue
    "#7799A6", // Grayish Blue
    "#592D14", // Dark Slate Blue
    "#8C5637", // Dark Cyan
    "#A66E4E",
  ];

  const isDataUnavailable = !data || Object.keys(submissionData).length === 0;

 return (
   <div className="p-5 bg-white shadow-md rounded-lg h-[100%]">
     <div className="flex justify-between items-center mb-5">
       <div className="flex space-x-2">
         {Object.keys(timeRanges).map((rangeKey) => (
           <button
             key={rangeKey}
             className={`px-3 py-1 font-semibold rounded-lg shadow-md focus:outline-none ${
               selectedTimeRange === timeRanges[rangeKey]
                 ? "bg-gradient-to-r from-blue-500 to-blue-700 text-white"
                 : "bg-gray-200 text-gray-700 hover:bg-gray-300"
             }`}
             onClick={() => setSelectedTimeRange(timeRanges[rangeKey])}
           >
             {rangeKey.replace(/_/g, " ")}
           </button>
         ))}
       </div>
     </div>

     {/* Graph container with overlay and loading spinner */}
     <div className="relative">
       <ResponsiveContainer width="100%" height={435}>
         <LineChart>
           <CartesianGrid strokeDasharray="3 3" vertical={false} />
           <XAxis dataKey="date" tick={{ fontSize: 12 }} />
           <YAxis hide />
           <Tooltip />
           <Legend />
           {submissionData.map((course,index) => (
             <Line
               key={course.courseName}
               type="linear"
               dataKey="submissions"
               data={course.dates}
               name={course.courseName} // Display course name in the legend
               stroke={colors[index % colors.length]} // You can define a function to assign colors
               strokeWidth={3}
               dot={false}
             />
           ))}
         </LineChart>
       </ResponsiveContainer>

       {/* Overlay when data is unavailable */}
       {!loading && isDataUnavailable && (
         <div className="absolute inset-0 bg-gray-200 bg-opacity-80 flex flex-col items-center justify-center z-10">
           <GrCircleInformation className="text-primary h-10 w-10 mb-2" />
           <div className="text-primary text-lg font-semibold">
             Begin your journey to track your progress
           </div>
         </div>
       )}

       {/* Loading spinner overlay */}
       {loading && (
         <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10">
           <Spinner />
         </div>
       )}
     </div>
   </div>
 );

};

export default DailyActivityGraph;
