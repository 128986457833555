import React from "react";
import AdminLayout from "../../Layout/index.jsx";
import { useGetClustersByCampusQuery } from "../../../../graphql/generated.tsx";
import LeaderboardStaff from "../../Components/LeaderBoardStaff.jsx";

const StaffDashboard = () => {
  let campusDetails = null;
  let staffData = null;

  try {
    const campusDetailsString = localStorage.getItem("campusDetails");
    campusDetails = campusDetailsString ? JSON.parse(campusDetailsString) : null;
  } catch (error) {
    console.error("Error parsing campusDetails from localStorage", error);
  }

  try {
    const staffDataString = localStorage.getItem("staffData");
    staffData = staffDataString ? JSON.parse(staffDataString) : null;
  } catch (error) {
    console.error("Error parsing staffData from localStorage", error);
  }

  const campusName = campusDetails ? campusDetails.name : null;
  const { data } = useGetClustersByCampusQuery({
    variables: { campusName },
  });
  return (
    <AdminLayout>
      <div className="p-10">
        <h1 className="text-3xl font-semibold">
          Welcome, {staffData.name}
          <span className="text-xl font-light"> ({staffData.designation})</span>
        </h1>
        {data?.getClustersByCampus.map((cluster) => (
          <LeaderboardStaff clusterId={cluster.id} campusName={campusName} />
        ))}
      </div>
    </AdminLayout>
  );
};

export default StaffDashboard;
