import { useParams } from "react-router-dom";
import {
  useCompileCodeLazyQuery,
  useGetAssignementQuery,
  useGetAssignementQuestionQuery,
  useGetAssignmentSubmitQuery,
  useGetAutoSaveQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
  useSubmitAssignmentCodeMutation,
} from "../../../graphql/generated.tsx";
import React, { useState, useEffect, useRef } from "react";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";
import Compiler from "../../Practice/Components/Compiler";
import CustomInput from "../../Practice/Components/CustomInput";
import RunModel from "../../Practice/Components/RunModel";
import ErrorModel from "../../Practice/Components/ErrorModel";
import { Button } from "flowbite-react";
import TestLayout from "../Layout/TestLayout.jsx";
import CompilerQuestionCard from "../Components/CompilerQuestionCard.jsx";
import BackButton from "../Components/BackButton.jsx";
import SumbitModel from "../../Practice/Components/SumbitModel";

const AssignmentTestQuestion = () => {
  const { questionId, assignmentId } = useParams();
  const [code, setCode] = useState("");

  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [input, setInput] = useState("");
  const [question, setQuestion] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const errorRef = useRef(null);
  const [testResults, setTestResults] = useState([]);
  const [run, setRun] = useState(false);
  const [submit, setSubmit] = useState(false);
  const tableRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(true);

  const { data, loading } = useGetAssignementQuestionQuery({
    variables: { id: questionId },
  });

  const [prevSubmission, setPrevSubmission] = useState({});
  const studentEmail = JSON.parse(localStorage.getItem("user")).email;
  const [autosave, setAutoSave] = useState();

  const { data: student } = useGetStudentByEmailIdQuery({
    variables: { email: studentEmail },
  });
  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId?.campusId },
    skip: !student?.getStudentByEmailId,
  });

  const { data: PreviousSubmissions, refetch } = useGetAssignmentSubmitQuery({
    variables: {
      assignmentId,
      studentId: student?.getStudentByEmailId?.id,
      questionId,
    },
  });

  useEffect(() => {
    refetch();
    if (PreviousSubmissions?.getAssignmentSubmit) {
      setPrevSubmission(PreviousSubmissions.getAssignmentSubmit);
    }
  }, [PreviousSubmissions]);

  const { data: Assignment } = useGetAssignementQuery({
    variables: { id: assignmentId },
  });
  const [language, setLanguage] = useState();
  useEffect(() => {
    setLanguage(Assignment?.getAssignement?.languages[0].toUpperCase());
  }, [Assignment?.getAssignement]);

  const [
    compileCode,
    { data: compileData, loading: compileLoading, error: compileerror },
  ] = useCompileCodeLazyQuery();

  const [submitCode, { data: submitData, loading: submitLoading }] =
    useSubmitAssignmentCodeMutation();

  useEffect(() => {
    if (compileData) {
      setTestResults(compileData.compileCode.Results);
    }
  }, [compileData]);

  const extractFunctionCode = (code, language, targetFunctionName) => {
    let functionRegex;

    switch (language) {
      case "python":
        functionRegex = new RegExp(
          `def\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*:[\\s\\S]*?(?=\\n\\S|$)`,
          "g"
        );
        break;
      case "java":
        functionRegex = new RegExp(
          `public\\s+static\\s+\\w+\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*{[^}]*}`,
          "g"
        );
        break;
      case "cpp":
      case "c":
        functionRegex = new RegExp(
          `\\w+(\\s*\\*\\s*|\\s+)${targetFunctionName}\\s*\\([^)]*\\)\\s*{([\\s\\S]*?)}(?![^}]*})`,
          "g"
        );
        break;
      default:
        return "";
    }

    const match = code?.match(functionRegex);
    return match ? match[0] : "";
  };
  const { data: autoSave, refetch: autoSaveRefetch } = useGetAutoSaveQuery({
    variables: {
      question_id: questionId,
      student_id: student?.getStudentByEmailId?.id,
    },
  });
  useEffect(() => {
    autoSaveRefetch();
    if (autoSave?.getAutoSave) {
      setAutoSave(autoSave?.getAutoSave);
    }
  }, [autoSave]);

  useEffect(() => {
    if (data) {
      setQuestion(data.getAssignementQuestion);
      const langKey = language?.toLowerCase();

      const previousQuestion = prevSubmission?.questions?.find(
        (ques) => ques.question_id === questionId
      );

      if (PreviousSubmissions?.getAssignmentSubmit) {
        setCode(
          extractFunctionCode(
            previousQuestion?.answer,
            langKey,
            data?.getAssignementQuestion?.targetFunctionName
          )
        );
        setLanguage(previousQuestion?.language);
      } else if (autosave) {
        setCode(autosave?.code);
      } else if (
        data?.getAssignementQuestion?.showFunctionOnly &&
        data?.getAssignementQuestion?.predefinedCode[langKey]
      ) {
        const targetFunctionCode = extractFunctionCode(
          data?.getAssignementQuestion?.predefinedCode[langKey],
          langKey,
          data?.getAssignementQuestion.targetFunctionName
        );
        setCode(targetFunctionCode);
      } else if (data?.getAssignementQuestion?.predefinedCode[langKey]) {
        setCode(data.getAssignementQuestion.predefinedCode[langKey]);
      }
    }
  }, [data, language, prevSubmission, autosave]);

  const handleFullScreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [testResults]);

  useEffect(() => {
    if (error) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  useEffect(() => {
    if (compileData?.compileCode) {
      setTestResults(compileData.compileCode.Results);
    }
  }, [compileData]);

  useEffect(() => {
    if (submitData?.submitAssignmentCode) {
      setOutput(submitData.submitAssignmentCode);
    }
  }, [submitData]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setRun(false);
    setInput(""); // Reset input when checkbox is toggled
  };

  const handleSubmit = async () => {
    try {
      await submitCode({
        variables: {
          assignmentCompilerCodeInput: {
            code,
            language,
            questionid: question.id,
            testCases: question?.test_cases?.map((test) => ({
              id: test.id,
              input: test.input,
              output: test.output,
              visibility: test.visibility,
              weightage: parseFloat(test.weightage),
            })),
          },
          assignmentSubmitInput: {
            assignment_id: assignmentId,
            question_id: question.id,
            student_id: student.getStudentByEmailId.id,
            cluster_id: student.getStudentByEmailId.clusterIds[0],
            campus_name: campus?.campus?.name,
          },
        },
      });
      setSubmit(true);
      setRun(false);
      setInput("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRun = async () => {
    try {
      setSubmit(false);
      setError(false);

      let data;

      if (!isChecked) {
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: question?.test_cases?.map((test) => ({
                id: test.id,
                input: test.input,
                output: test.output,
                visibility: test.visibility,
                weightage: parseFloat(test.weightage),
              })),
              language,
              questionid: question.id,
            },
          },
        });
        setRun(true);
        data = response.data;
      } else {
        console.log({ input });
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: [
                {
                  id: "0",
                  input: input,
                  output: " ",
                  visibility: "SAMPLE",
                  weightage: 0,
                },
              ],
              language,
              questionid: question.id,
            },
          },
        });
        console.log({ response });
        data = response.data;
      }

      if (data) {
        setRun(true);
        setTestResults(data.compileCode.Results);
      }
    } catch (err) {
      setTestResults([]); // Clear previous test results in case of error

      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        const errorMessage = err.graphQLErrors[0].message;
        setError(errorMessage);
      } else {
        setError(
          "There could some logic error in your code or network connectivity issue. Kindly try again later"
        );
      }
    }
  };

  const setToDefault = () => {
    const langKey = language?.toLowerCase();
    if (
      data?.getAssignementQuestion.showFunctionOnly &&
      data?.getAssignementQuestion.predefinedCode[langKey]
    ) {
      const targetFunctionCode = extractFunctionCode(
        data.getAssignementQuestion.predefinedCode[langKey],
        langKey,
        data.getAssignementQuestion.targetFunctionName
      );
      setCode(targetFunctionCode);
    } else if (data?.getAssignementQuestion.predefinedCode[langKey]) {
      setCode(data.getAssignementQuestion.predefinedCode[langKey]);
    }
  };

  return (
    <TestLayout assignmentId={assignmentId} disableSubmit={true}>
      {isFullScreen ? (
        <div className="pt-8 bg-slate-100">
          <BackButton
            setFullScreen={setFullScreen}
            isFullScreen={isFullScreen}
          />
          <div className={"flex"}>
            <div className={"w-1/2 pr-4"}>
              <CompilerQuestionCard
                question={question}
                loading={loading}
                submitInput={() => {}}
                onApply={setCode}
              />
            </div>
            <div className={"w-1/2 pl-4"}>
              <Compiler
                student_id={student?.getStudentByEmailId?.id}
                question_id={questionId}
                language={language}
                code={code}
                setToDefault={setToDefault}
                setLanguage={setLanguage}
                handleCodeChange={handleCodeChange}
                errorRef={errorRef}
                handleSubmit={handleSubmit}
                handleRun={handleRun}
                CourseData={Assignment?.getAssignement}
                compileLoading={compileLoading}
                submitLoading={submitLoading}
              />
              <CustomInput
                isChecked={isChecked}
                handleCheckboxChange={handleCheckboxChange}
                setInput={setInput}
                input={input}
              />

              {!compileerror && run && testResults?.length != 0 && (
                <RunModel
                  tableRef={tableRef}
                  isChecked={isChecked}
                  testResults={testResults}
                  loading={compileLoading}
                />
              )}

              {output && submit && (
                <SumbitModel output={output} question={question} />
              )}

              {compileerror && !submit && (
                <ErrorModel error={compileerror} language={language} />
              )}

              {testResults.length === 0 &&
                !submit &&
                run &&
                !compileLoading &&
                !compileerror && (
                  <ErrorModel
                    error={
                      compileData?.compileCode?.testCaseFailed?.testCaseIds[0]
                        ?.stdout
                    }
                    language={language}
                    runtimeError={testResults.length === 0}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-screen h-screen bg-gray-100">
          <h1 className="text-xl mb-4">
            Please switch to Fullscreen to continue
          </h1>
          <Button className="bg-primary p-0" onClick={toggleFullScreen}>
            Full Screen
          </Button>
        </div>
      )}
    </TestLayout>
  );
};

export default AssignmentTestQuestion;
