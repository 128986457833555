import React, { useEffect, useState } from "react";
import {
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useGetAllAdminQuery,
} from "../../../../graphql/generated.tsx";
import { IoMdAdd, IoMdArrowRoundBack, IoMdTrash } from "react-icons/io";
import { Button, Table, Modal, Label, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import AdminLayout from "../../Layout/index.jsx";

const SuperAdmin = () => {
  const { loading, error, data } = useGetAllAdminQuery();
  const [admins, setAdmins] = useState([]);
  const [createAdmin] = useCreateAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);

  useEffect(() => {
    if (data) {
      setAdmins(data.getAllAdmin);
    }
  }, [data]);
  console.log({ data });

  const handleCreateAdmin = async () => {
    try {
      const { data: newAdminData } = await createAdmin({
        variables: { CreateAdminInput: { name, email } },
      });

      if (newAdminData.createAdmin.message.includes("already exists")) {
        toast.error("Email already exists");
      } else {
        setAdmins((prev) => [...prev, newAdminData.createAdmin.admin]);
        toast.success("Admin Added Successfully")
        setOpenModal(false);
      }
    } catch (err) {
      console.error("Error creating admin:", err);
    }
  };


  const handleDeleteAdmin = async (id) => {
    try {
      await deleteAdmin({
        variables: { id },
      });
      setAdmins((prev) => prev.filter((a) => a.id !== id));
      toast.success("Admin deleted successfully");
    } catch (err) {
      console.error("Error deleting admin:", err);
      toast.error("Failed to delete admin");
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              Admin Details
            </div>
          </div>
          <div className="flex">
            <Button
              onClick={() => setOpenModal(true)}
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0"
            >
              <IoMdAdd className="mr-1 mt-1" /> <span>Create Admin</span>
            </Button>
          </div>
        </div>
        <div className="mt-10">
          <Table className="min-w-full bg-white border border-gray-300">
            <Table.Head>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {admins?.map((admin) => (
                <Table.Row
                  key={admin.id}
                  onMouseEnter={() => setHoveredRow(admin.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className="hover:bg-gray-50"
                >
                  <Table.Cell>{admin.name}</Table.Cell>
                  <Table.Cell>{admin.email}</Table.Cell>
                  <Table.Cell>
                    {hoveredRow === admin.id && (
                      <IoMdTrash
                        className="h-5 w-5 cursor-pointer text-red-500"
                        onClick={() => handleDeleteAdmin(admin.id)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        {/* Modal for creating admin */}
        <Modal
          show={openModal}
          size="md"
          popup
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Create a new admin member
              </h3>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Admin Name" />
                </div>
                <TextInput
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter admin name"
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email" value="Admin Email" />
                </div>
                <TextInput
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter admin email"
                  required
                />
              </div>
              <div className="w-full">
                <Button onClick={handleCreateAdmin}>Create Admin</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default SuperAdmin;
