import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommandLineIcon } from "@heroicons/react/24/solid";
import { IoMdArrowRoundBack } from "react-icons/io";
import { CiClock2, CiViewList } from "react-icons/ci";
import { GrScorecard } from "react-icons/gr";
import { BsQuestionSquare } from "react-icons/bs";
import { RiQuestionAnswerFill } from "react-icons/ri";
import {
  useGetAllAssignementQuestionQuery,
  useGetAssignementQuery,
  useGetAssignmentSubmitQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import AssignmentLayout from "../Layout/index.jsx";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import QuestionCard from "../Components/QuestionCard.jsx";
import { GiAlarmClock } from "react-icons/gi";
import { Button, Tabs } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import StudentLayout from "../../Student/Layout/index.jsx";

const AssignmentDetails = () => {
  const { assignmentId } = useParams();
  const [assignment, setAssignments] = useState();
  const [assignmentQuestion, setAssignmentQuestion] = useState();
  const navigate = useNavigate();
  const { data, loading } = useGetAssignementQuery({
    variables: { id: assignmentId },
  });
  const { data: AssignmentQuestions } = useGetAllAssignementQuestionQuery({
    variables: { assignmentId },
  });
  const studentEmail = JSON?.parse(localStorage.getItem("user"))?.email;
  const { data: student } = useGetStudentByEmailIdQuery({
    variables: { email: studentEmail },
  });
  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId?.campusId },
    skip: !student?.getStudentByEmailId
  });

  const { data: AssignmentCompleted } = useGetAssignmentSubmitQuery({
    variables: {
      assignmentId,
      studentId: student?.getStudentByEmailId.id,
      campus_name: campus?.campus?.name,
    },
    skip: !student?.getStudentByEmailId || !campus?.campus,
  });

  useEffect(() => {
    if (data?.getAssignement) setAssignments(data?.getAssignement);
  }, [data]);

  useEffect(() => {
    if (AssignmentQuestions?.getAllAssignementQuestion) {
      setAssignmentQuestion(AssignmentQuestions.getAllAssignementQuestion);
    }
  }, [AssignmentQuestions]);

  if (loading) {
    return (
      <AssignmentLayout>
        <div className="flex items-center justify-center h-screen">
          Loading...
        </div>
      </AssignmentLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="p-8 max-md:p-5">
        <button
          onClick={() => navigate("/assignments")}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
        <div className="px-6 py-8 rounded-md shadow-sm bg-white relative mt-5">
          <div className="absolute top-7 right-5">
            <Button
              disabled={AssignmentCompleted?.getAssignmentSubmit?.completed}
              className="bg-primary"
              onClick={() => navigate("test-screen")}
            >
              Start Now
            </Button>
          </div>
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="inline-block bg-orange-100 p-6 rounded-lg max-md:w-1/2">
              <CommandLineIcon className="h-12 w-12 text-orange-500" />
            </div>
            <div className="md:max-w-screen-sm">
              <div className="font-bold text-xl mb-2">{assignment?.name}</div>
              <div className="text-slate-500 text-sm">
                {assignment?.description}
              </div>
            </div>
          </div>
          <hr className="w-screen-md my-6" />
          <div className="flex gap-32 flex-wrap">
            <div className="flex flex-col justify-center items-center">
              <CiClock2 className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">Due Date</span>
              <span className="font-bold text-red-500">
                {formatDate(assignment?.due_date) || "Not Set"}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <CiViewList className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">
                Number of Questions
              </span>
              <span className="font-bold">
                {assignmentQuestion ? assignmentQuestion.length : 0}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <GiAlarmClock className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">Duration</span>
              <span className="font-bold">
                {assignment ? `${assignment?.duration} hrs` : 0}
              </span>
            </div>
            {AssignmentCompleted?.getAssignmentSubmit?.completed && (
              <div className="flex flex-col justify-center items-center">
                <GrScorecard className="w-5 h-5 text-slate-500" />
                <span className="text-sm text-slate-500 mb-1">Your Score</span>
                <span className="font-bold text-green-500">
                  {AssignmentCompleted
                    ? AssignmentCompleted?.getAssignmentSubmit?.totalScore
                    : 0}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8 bg-white p-8 max-md:p-5 rounded-md min-h-2xl">
          <Tabs aria-label="Tabs with underline" variant="underline">
            <Tabs.Item active title="Questions" icon={BsQuestionSquare}>
              {assignmentQuestion?.map((question, index) => {
                const completedQuestion = AssignmentCompleted
                  ?.getAssignmentSubmit?.questions
                  ? AssignmentCompleted?.getAssignmentSubmit?.questions?.find(
                      (ques) => ques.question_id === question.id
                    )
                  : [];
                return (
                  <div key={index}>
                    <QuestionCard
                      data={question}
                      scoreObtained={completedQuestion?.score}
                      isCompleted={
                        AssignmentCompleted?.getAssignmentSubmit?.completed
                      }
                    />
                  </div>
                );
              })}
            </Tabs.Item>
            {AssignmentCompleted?.getAssignmentSubmit?.completed && (
              <Tabs.Item title="Submissions" icon={RiQuestionAnswerFill}>
                <div className="space-y-4">
                  {AssignmentCompleted?.getAssignmentSubmit?.questions?.map(
                    (submission, index) => {
                      const question = assignmentQuestion?.find(
                        (ques) => ques.id === submission.question_id
                      );
                      return (
                        <div
                          key={index}
                          className="p-4 border rounded-md bg-gray-50"
                        >
                          <div className="flex justify-between items-center">
                            <div className="text-lg font-semibold">
                              {question?.title}
                            </div>
                            <div className="text-green-500 font-bold">
                              Score: {submission.score}
                            </div>
                          </div>
                          <div className="text-sm text-gray-600 mt-2">
                            {question?.description}
                          </div>
                          <div className="mt-2">
                            <span className="text-gray-500">Your Answer:</span>
                            <pre className="bg-white p-2 rounded max-w-full overflow-x-auto mt-1">
                              {submission.answer}
                            </pre>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </Tabs.Item>
            )}
          </Tabs>
        </div>
      </div>
    </StudentLayout>
  );
};

export default AssignmentDetails;
