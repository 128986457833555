import React from "react";

const QuestionCard = ({ data, scoreObtained, isCompleted }) => {
  function getDifficultyColor(difficulty) {
    switch (difficulty) {
      case "EASY":
        return "bg-green-200 text-gray-800";
      case "MEDIUM":
        return "bg-yellow-100 text-gray-800";
      case "HARD":
        return "bg-red-200 text-gray-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  }

  const difficulty =
    data?.difficulty?.charAt(0).toUpperCase() +
    data?.difficulty?.slice(1).toLowerCase();

  return (
    <div className="p-4 mb-4 border rounded-lg shadow-sm bg-white hover:shadow-md transition-shadow duration-300">
      <div className="flex justify-between items-start mb-2">
        <div>
          <div className="font-bold text-xl text-gray-900 mb-2">
            {data.title}
          </div>
          <div className="text-sm text-gray-500">Max Score: {data.marks}</div>
        </div>
        {data?.difficulty && (
          <div
            className={`p-1 text-xs text-center font-light rounded-md ${getDifficultyColor(
              data.difficulty
            )}`}
          >
            {difficulty}
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-wrap gap-2 mb-2 md:mb-0">
          {data?.tags &&
            data.tags.map((tag, index) => (
              <span
                key={index}
                className="inline-block bg-blue-100 text-blue-800 rounded px-2 py-1 text-xs font-medium"
              >
                {tag}
              </span>
            ))}
        </div>
        {scoreObtained && isCompleted && (
          <div className="text-right">
            <div className="text-gray-700">
              Obtained Marks:{" "}
              <span className="font-bold text-green-600">{scoreObtained}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
