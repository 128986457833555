import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import { useGetStudentSubmissionsForMonthQuery } from "../../../graphql/generated.tsx";
import { Spinner } from "flowbite-react";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
          fontSize: "12px",
        }}
      >
        <p>Date: {label}</p>
        <p>Submissions: {payload[0].value}</p>
      </div>
    );
  }
  return null;
};

const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const currentYear = new Date().getFullYear();
const startYear = 2024;
const years = [];

for (let year = startYear; year <= currentYear; year++) {
  years.push(year);
}

const SubmissionGraph = ({ studentId, campusName }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const { data, loading } = useGetStudentSubmissionsForMonthQuery({
    variables: {
      studentId,
      campusName,
      month: selectedMonth,
      year: selectedYear,
    },
  });

  const submissionData = data?.getStudentSubmissionsForMonth.map((entry) => ({
    date: new Date(entry.date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }),
    submissions: entry.submissions.length,
  }));

  // Calculate the average submissions for the dashed line
  const averageSubmissions =
    submissionData?.reduce((acc, curr) => acc + curr.submissions, 0) /
      submissionData?.length || 0;

  return (
    <div className="p-5 bg-white shadow-md rounded-lg relative">
      <div className="flex justify-between items-center mb-5">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          className="block w-1/2 p-2 text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        >
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.name}
            </option>
          ))}
        </select>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          className="block w-1/2 p-2 ml-2 text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-[250px]">
          <Spinner />
        </div>
      ) : submissionData?.length === 0 ? (
        <div className="flex justify-center items-center h-[250px]">
          <div className="text-gray-600 text-lg font-semibold">
            No submission data available
          </div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={247}>
          <BarChart data={submissionData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12 }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis axisLine={false} tickLine={false} />
            {/* Use the CustomTooltip component here */}
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}
            />
            <ReferenceLine
              y={averageSubmissions}
              stroke="#0E3D5D"
              strokeDasharray="3 3"
            />
            <Bar
              dataKey="submissions"
              fill="#155887"
              barSize={10}
              radius={[5, 5, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default SubmissionGraph;
