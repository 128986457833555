import React, { useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "flowbite-react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useGetClustersByCampusQuery } from "../../../../graphql/generated.tsx";

const ReportsClusterListing = () => {
  const { campusName } = useParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;

  const { data, refetch } = useGetClustersByCampusQuery({
    variables: { campusName, searchTerm, page, limit },
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    refetch({ campusName, searchTerm: e.target.value, page: 1, limit });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    refetch({ campusName, searchTerm, page: newPage, limit });
  };
  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              {campusName} Clusters
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearch}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="mt-10">
          <Table className="min-w-full bg-white border border-gray-300">
            <TableHead>
              <TableHeadCell className="text-center">Name</TableHeadCell>
              <TableHeadCell className="text-center">
                No of Students
              </TableHeadCell>
            </TableHead>
            <TableBody>
              {data?.getClustersByCampus?.map((cluster) => (
                <TableRow
                  key={cluster.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => navigate(`${cluster.id}`)}
                >
                  <TableCell className="text-center">{cluster.name}</TableCell>
                  <TableCell className="text-center">
                    {cluster.studentCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
            >
              Previous
            </button>
            <span>Page {page}</span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={data?.getClustersByCampus?.length < limit}
              className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ReportsClusterListing;
