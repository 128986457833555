import { Table, TableCell, TableRow } from "flowbite-react";

export function SkeletonRow() {
  return (
    <TableRow className="animate-pulse">
      <TableCell className="py-3 px-6">
        <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-24"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-20"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-12"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-16"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-20"></div>
      </TableCell>
      <TableCell className="py-3 px-6">
        <div className="h-4 bg-gray-300 rounded w-24"></div>
      </TableCell>
    </TableRow>
  );
}
