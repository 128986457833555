import { Route } from "react-router-dom";
import Campus from "../Modules/Admin/Screens/Manage_Campus/Campus";
import CampusDetails from "../Modules/Admin/Screens/Manage_Campus/CampusDetails";
import SuperAdmin from "../Modules/Admin/Screens/Bt_Admin/SuperAdmin";
import ManageClusters from "../Modules/Admin/Screens/Manage_Cluster/ManageClusters";
import ClusterListing from "../Modules/Admin/Screens/Manage_Cluster/ClusterListing";
import StudentDetails from "../Modules/Admin/Screens/Student_Creation/StudentDetails";
import CreateStudents from "../Modules/Admin/Screens/Student_Creation/CreateStudents";
import ClusterStudentListing from "../Modules/Admin/Screens/Manage_Cluster/ClusterStudentListing";
import Courses from "../Modules/Admin/Screens/Course/Courses";
import Assignments from "../Modules/Admin/Screens/Assignments/Assignments";
import CreateCourse from "../Modules/Admin/Screens/Course/CreateCourse";
import CreateAssignment from "../Modules/Admin/Screens/Assignments/CreateAssignment";
import CourseDetails from "../Modules/Admin/Screens/Course/CourseDetails";
import AssignmentDetails from "../Modules/Admin/Screens/Assignments/AssignmentDetails";
import QuestionListing from "../Modules/Admin/Screens/Course/Questions/QuestionListing";
import CreateAssignmentQuestion from "../Modules/Admin/Screens/Assignments/Questions/CreateAssignmentQuestion";
import EditAssignmentQuestion from "../Modules/Admin/Screens/Assignments/Questions/EditAssignmentQuestion";
import CreateQuestion from "../Modules/Admin/Screens/Course/Questions/CreateQuestion";
import EditQuestion from "../Modules/Admin/Screens/Course/Questions/EditQuestion";
import ImportAssignmentQuestion from "../Modules/Admin/Screens/Assignments/Questions/ImportAssignmentQuestion";
import AssignToStudents from "../Modules/Admin/Screens/Assignments/AssignToStudents";
import Studentlisting from "../Modules/Admin/Screens/Assignments/Studentlisting";
import HomePage from "../Modules/HomePage";
import ImportQuestion from "../Modules/Admin/Screens/Course/Questions/ImportQuestion";

const SuperAdminRoutes = [
  <Route key="campus" path="admin/campus" element={<Campus />} />,
  <Route
    key="campus-details"
    path="admin/campus/:id"
    element={<CampusDetails />}
  />,
  <Route key="bt-admin" path="admin/bt-admin" element={<SuperAdmin />} />,
  <Route
    key="manage-cluster"
    path="admin/clusters/campus-list"
    element={<ManageClusters />}
  />,
  <Route
    key="cluster-listing"
    path="admin/clusters/:campusName"
    element={<ClusterListing />}
  />,
  <Route
    key="student-data"
    path="admin/students/:id"
    element={<StudentDetails />}
  />,
  <Route
    key="create-student"
    path="admin/students/:id/create-students"
    element={<CreateStudents />}
  />,
  <Route
    key="cluster-student-listing"
    path="admin/clusters/:campusName/:clusterId"
    element={<ClusterStudentListing />}
  />,
  <Route key="courses" path="/admin/courses" element={<Courses />} />,
  <Route
    key="assignments"
    path="/admin/assignments"
    element={<Assignments />}
  />,
  <Route
    key="createCourse"
    path="/admin/courses/create-course"
    element={<CreateCourse />}
  />,
  <Route
    key="createAssignment"
    path="/admin/assignments/create-assignment"
    element={<CreateAssignment />}
  />,
  <Route
    key="courseDetails"
    path="/admin/courses/:id"
    element={<CourseDetails />}
  />,
  <Route
    key="assignmentDetails"
    path="/admin/assignments/:id"
    element={<AssignmentDetails />}
  />,
  <Route
    key="questionListing"
    path="/admin/courses/:course_id/:cat_id"
    element={<QuestionListing />}
  />,
  <Route
    key="questionListing"
    path="/admin/courses/:course_id/:cat_id/import-question"
    element={<ImportQuestion />}
  />,
  <Route
    key="createAssignmentQuestion"
    path="/admin/assignments/:id/add-question"
    element={<CreateAssignmentQuestion />}
  />,
  <Route
    key="editAssignmentQuestion"
    path="/admin/assignments/:assignmentid/edit-question/:assignmentQuestionId"
    element={<EditAssignmentQuestion />}
  />,
  <Route
    key="importAssignmentQuestion"
    path="/admin/assignments/:assignmentid/import-question"
    element={<ImportAssignmentQuestion />}
  />,
  <Route
    key="AssignToStudents"
    path="/admin/assignments/:assignmentid/assign-to-students"
    element={<AssignToStudents />}
  />,

  <Route
    key="AssignToStudents"
    path="/admin/assignments/:assignmentId/assign-to-students/:campusId"
    element={<Studentlisting />}
  />,
  <Route
    key="createQuestion"
    path="/admin/courses/:id/add-question"
    element={<CreateQuestion />}
  />,
  <Route
    key="editQuestion"
    path="/admin/courses/:courseid/edit-question/:questionid"
    element={<EditQuestion />}
  />,
];

export default SuperAdminRoutes;
