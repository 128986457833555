import React, { useState, useEffect } from "react";
import { useGetStudentsByCampusQuery } from "../../../../graphql/generated.tsx";
import { Table, Pagination } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../Layout/index.jsx";

const AllStudentsInCampus = () => {
  const campus = JSON.parse(localStorage.getItem("campusDetails")) || "";
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const limit = 10;

  const { loading, error, data, refetch } = useGetStudentsByCampusQuery({
    variables: {
      campusId: campus.id,
      page,
      limit,
      searchTerm: debouncedSearchTerm,
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPage(1); // Reset to first page when search term changes
      refetch({ campusId: campus.id, page, limit, searchTerm }); // Refetch with new search term
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, refetch, campus.id, limit]);

  if (loading)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          Loading...
        </div>
      </AdminLayout>
    );
  if (error)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          Error: {error.message}
        </div>
      </AdminLayout>
    );

  const handleRowClick = (id) => {
    navigate(`${id}`);
  };

  const totalPages = Math.ceil(data?.getStudentsByCampus.total / limit);
  return (
    <AdminLayout>
      <div className="p-10">
        <h2 className="text-3xl font-bold mb-4 text-center">
          {campus.name}'s Students
        </h2>
        <div className="flex justify-end mb-4">
          <input
            type="text"
            className="border rounded px-4 py-2"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto shadow-md sm:rounded-lg mt-10">
          <Table className="min-w-full divide-y divide-gray-200 ">
            <Table.Head className="bg-gray-100">
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </Table.HeadCell>
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </Table.HeadCell>
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                RollNo
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="bg-white divide-y divide-gray-200">
              {data?.getStudentsByCampus.students.map((student) => (
                <Table.Row
                  key={student.id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRowClick(student.id)}
                >
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.name || "N/A"}
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.email}
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.rollNo}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
              refetch({
                campusId: campus.id,
                page: newPage,
                limit,
                searchTerm: debouncedSearchTerm,
              });
            }}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default AllStudentsInCampus;
