import React, { useEffect, useState } from "react";
import { Sidebar } from "flowbite-react";
import { BiBuoy } from "react-icons/bi";
import { RiAdminLine } from "react-icons/ri";
import { FaBuildingColumns } from "react-icons/fa6";
import { HiChartPie, HiUser, HiViewBoards } from "react-icons/hi";
import { MdAssignment } from "react-icons/md";
import { auth } from "../../../utils/firebase";
import { AiOutlineCluster } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useGetAdminUsingEmailQuery } from "../../../graphql/generated.tsx";

function LeftSideBar() {
  const [currentPage, setCurrentPage] = useState("");
  const [user, setUser] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setEmail(user?.email);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const { data: admin } = useGetAdminUsingEmailQuery({ variables: { email } });

  const userRole = user
    ? JSON.parse(user?.reloadUserInfo?.customAttributes).role
    : null;

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  const customTheme = {
    root: {
      base: "bg-white",
      inner: "bg-white",
    },
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update isMobile state based on window width
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let staffCampusName = "";
  if (userRole === "staff") {
    staffCampusName = localStorage.getItem("campusDetails")
      ? JSON.parse(localStorage.getItem("campusDetails")).name
      : "";
  }

  return (
    <div className="lg:w-64 lg:flex-shrink-0 h-full">
      <Sidebar
        collapsed={isMobile}
        theme={customTheme}
        className="flex p-4 mt-3 fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700"
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {/* <Link to={"/admin"}>
              <Sidebar.Item
                icon={HiChartPie}
                className={
                  "/admin" === currentPage.toLowerCase()
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                }
              >
                Dashboard
              </Sidebar.Item>
            </Link> */}

            {userRole === "staff" && (
              <>
                <Link to={"/admin/student-details"}>
                  <Sidebar.Item
                    icon={HiUser}
                    className={
                      currentPage.startsWith("/admin/student-details")
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Students
                  </Sidebar.Item>
                </Link>
              </>
            )}
            {userRole === "bt-admin" && (
              <>
                <Link to={"/admin/courses"}>
                  <Sidebar.Item
                    icon={HiViewBoards}
                    className={
                      currentPage.startsWith("/admin/courses")
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Courses
                  </Sidebar.Item>
                </Link>
                {admin?.getAdminUsingEmail.id !==
                  "538f3456-6b82-4608-8934-d719294e9791" && (
                  <>
                    <Link to={"/admin/assignments"}>
                      <Sidebar.Item
                        icon={MdAssignment}
                        className={
                          currentPage.startsWith("/admin/assignments")
                            ? "bg-gray-100 dark:bg-gray-700"
                            : ""
                        }
                      >
                        Assignments
                      </Sidebar.Item>
                    </Link>
                    <Link to={"/admin/campus"}>
                      <Sidebar.Item
                        icon={FaBuildingColumns}
                        className={
                          currentPage.startsWith("/admin/campus")
                            ? "bg-gray-100 dark:bg-gray-700"
                            : ""
                        }
                      >
                        Manage Campus
                      </Sidebar.Item>
                    </Link>
                    <Link to={"/admin/students"}>
                      <Sidebar.Item
                        icon={HiUser}
                        className={
                          currentPage.startsWith("/admin/students")
                            ? "bg-gray-100 dark:bg-gray-700"
                            : ""
                        }
                      >
                        Students Creation
                      </Sidebar.Item>
                    </Link>
                    <Link to={"/admin/bt-admin"}>
                      <Sidebar.Item
                        icon={RiAdminLine}
                        className={
                          currentPage.startsWith("/admin/bt-admin")
                            ? "bg-gray-100 dark:bg-gray-700"
                            : ""
                        }
                      >
                        BT-Admin
                      </Sidebar.Item>
                    </Link>
                    <Link to={"/admin/clusters/campus-list"}>
                      <Sidebar.Item
                        icon={AiOutlineCluster}
                        className={
                          currentPage.startsWith("/admin/clusters")
                            ? "bg-gray-100 dark:bg-gray-700"
                            : ""
                        }
                      >
                        Manage Cluster
                      </Sidebar.Item>
                    </Link>
                  </>
                )}
              </>
            )}
          </Sidebar.ItemGroup>

          <Sidebar.ItemGroup>
            {(userRole === "bt-admin" || userRole === "staff") &&
              admin?.getAdminUsingEmail.id !==
                "538f3456-6b82-4608-8934-d719294e9791" && (
                <Link
                  to={
                    userRole === "bt-admin"
                      ? "/admin/reports"
                      : userRole === "staff"
                      ? `/admin/reports/${staffCampusName}`
                      : "#"
                  }
                >
                  <Sidebar.Item
                    icon={HiChartPie}
                    className={
                      currentPage.startsWith("/admin/reports")
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Reports
                  </Sidebar.Item>
                </Link>
              )}

          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}

export default LeftSideBar;
